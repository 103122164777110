import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import { ItemComponent } from './item.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TranslateModule } from '@ngx-translate/core';
import { CountdownModule } from 'ngx-countdown';
import { LoaderModule } from '../loader/loader.module';
import { CardComponent } from '../card/card.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DontHaveItemsComponent } from '../dont-have-items/dont-have-items.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        BsDropdownModule.forRoot(),
        CollapseModule.forRoot(),
        TranslateModule,
        CountdownModule,
        LoaderModule,
        NgbTooltipModule,
        RouterModule
    ],
    exports: [ItemComponent, CardComponent, DontHaveItemsComponent],
    declarations: [ItemComponent, CardComponent, DontHaveItemsComponent],
    providers: [],
})
export class ItemModule {
}
