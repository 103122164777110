/*
export const contracts = {
  nfts_contract: "0x707916D886C91afd93aaf582D380619F687d5d0B",
  marketplace_contract: "0x2cF5094d1b2988c42993C847df34eb8EfF1f0dfd",
  usdt: "0xd8118C198cd705EEF992c242B9cE29A5A64cb609"
}; */

// Nuevos contratos
export const contracts = {
  nfts_contract: "0x958F06216495ea993DD527CCbD3ED5c9C3d4D515",
  marketplace_contract: "0xC1A18902983D66CFd45dEd5cAa66c63D80b22b04",
  usdt: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"
};

