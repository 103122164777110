import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dont-have-items',
  templateUrl: './dont-have-items.component.html',
  styleUrls: ['./dont-have-items.component.css']
})
export class DontHaveItemsComponent implements OnInit {
  @Input() text?: string;
  @Input() btnText?: string;
  @Input() btnUrl?: string;
  @Input() hideBtn?: boolean;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  routerLink = () => {
    if (this.btnUrl) {
      window.open(this.btnUrl, '_blank');
      return;
    }
    this.router.navigate(['/marketplace'])
  }
}
