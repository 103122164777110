            <div class="nft__item1" [style.border]="item.role == 1001 ? 'solid 1px #c5a500': 'solid 1px #FF004F'">
                <!-- <div class="de_countdown bg-color-secondary text-white" data-year="2021" data-month="8" data-day="16" data-hour="8">
                </div> -->

                <div  style="font-weight: bold; top: 0px; right: 0px; border-radius: 0px; border-bottom-left-radius: 30px;" 
                [style.background]="item.role == 1001 ? '#c5a500' : '#FF004F'"
                [style.border]="item.role == 1001 ? 'solid 1px #c5a500': 'solid 1px #FF004F'"
                *ngIf="item.sell_method == 2 && timeLeft() > 0" class="de_countdown bg-color-secondary text-white is-countdown">
                    <countdown [config]="cdConfig"></countdown>
                </div>

                <div class="image-container">
                    <a href="javascript:void(0)" (click)="verDetalle(item.tokenId)">
                        <img [src]="baseUrl + '/images/' + item.url" class="lazy " alt="">
                    </a>
                </div>

                <div class="tag" *ngIf="onSaleTag">
                    <img  style="position: relative; width: 45px; top: 3px; left: 3px;"
                    src="../../../assets/images/sale-white.svg" alt="onSale"
                    data-toggle="tooltip" title="{{'This NFT is on sale' | translate}}" class="d-inline-block">
                </div>

                <div class="nft__item_info1" >
                    <a href="javascript:void(0)" >
                        <div class="d-flex" style="gap: 10px;">
                            <h4 class="mb-0" [style.color]="item.role == 1001 ? '#c5a500' : '#FF004F'">{{item.title}}</h4>
                            <h4 class="mb-0" *ngIf="!item.title"> generic title</h4>
                            <img src="../../../assets/images/verify.svg" *ngIf="item.role == 1001"
                            alt="verify" style="width: 17px; margin-left: -1px;">
                        </div>
                        
                    </a>
                    <div class="nft__item_like" style=" padding-top: 8px;">
                        <!-- <img *ngIf="item.role == 1002"  src="../../../assets/images/etiqueta.png" alt="community" style="width: 45px;"> -->
                        <img *ngIf="item.role == 1001" src="../../../assets/images/tags/admin-tag.webp" alt="isAdmin" style="width: 40px;">
                        <!-- <img *ngIf="item." style="width: 48px;" src="../../../assets/images/tags/delivery-tag.webp" alt=""> -->
                    </div>
                    <h5 [style.visibility]="item.price ? 'visible' : 'hidden'" 
                    style="color: white!important; padding-top: 10px;" >{{item.price}} USDT</h5>
                    <p *ngIf="item.seller" (click)="viewOwner(item.seller.id)" style="cursor: pointer; color: white;"
                    translate>{{item.seller.username}}</p>
                    <!-- <button (click)="verItem()"></button> -->
                  <div class="text-center">
                        <button [style.background]="item.role == 1001 ? '#c5a500' : '#FF004F'"
                        style=" border: none;
                        font-weight: bold;
                        color: white!important;
                        width: 100%;
                        margin-top: 1rem;"
                        (click)="verDetalle(item.tokenId)">
                            <span translate>
                                VER MAS
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div class="nft__item1">
                -- <div class="de_countdown bg-color-secondary text-white" data-year="2021" data-month="8" data-day="16" data-hour="8">
                </div> --

                <div class="nft__item_wrap1">
                    <a href="javascript:void(0)">
                        <img [src]="'../../../assets/images/img2.png'" class="lazy " alt="">
                    </a>
                </div>
                <div class="nft__item_info1">
                    <a href="javascript:void(0)">
                        <h4 class="mb-0">LOREM IPSU</h4>
                    </a>
                    <div class="nft__item_like" style=" padding-top: 10px;">
                        <img src="../../../assets/images/etiqueta.png" alt="">
                    </div>
                    <h5 style="color: white!important; padding-top: 10px;">50 USDT</h5>
                    <div class="text-center">
                        <button style="background: #FF004F;
                        border: none;
                        font-weight: bold;
                        color: white!important;
                        width: 100%;
                        margin-top: 1rem;">
                            <span translate>
                                VER MAS
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div class="nft__item1">
                -- <div class="de_countdown bg-color-secondary text-white" data-year="2021" data-month="8" data-day="16" data-hour="8">
                </div> --

                <div class="nft__item_wrap1">
                    <a href="javascript:void(0)" >
                        <img  style="    width: 106%!important;" [src]="'../../../assets/images/img3.png'" class="lazy " alt="">
                    </a>
                </div>
                <div class="nft__item_info1">
                    <a href="javascript:void(0)">
                        <h4 class="mb-0">LOREM IPSU</h4>
                    </a>
                    <div class="nft__item_like" style=" padding-top: 10px;">
                        <img src="../../../assets/images/etiqueta.png" alt="">
                    </div>
                    <h5 style="color: white!important; padding-top: 10px;">50 USDT</h5>
                    <div class="text-center">
                        <button style="background: #FF004F;
                        border: none;
                        font-weight: bold;
                        color: white!important;
                        width: 100%;
                        margin-top: 1rem;">
                            <span translate>
                                VER MAS
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div class="nft__item1">
                -- <div class="de_countdown bg-color-secondary text-white" data-year="2021" data-month="8" data-day="16" data-hour="8">
                </div> --

                <div class="nft__item_wrap1">
                    <a href="javascript:void(0)">
                        <img [src]="'../../../assets/images/img4.png'" class="lazy " alt="">
                    </a>
                </div>
                <div class="nft__item_info1">
                    <a href="javascript:void(0)">
                        <h4 class="mb-0">LOREM IPSU</h4>
                    </a>
                    <div class="nft__item_like" style=" padding-top: 10px;">
                        <img src="../../../assets/images/etiqueta.png" alt="">
                    </div>
                    <h5 style="color: white!important; padding-top: 10px;">50 USDT</h5>
                    <div class="text-center">
                        <button style="background: #FF004F;
                        border: none;
                        font-weight: bold;
                        color: white!important;
                        width: 100%;
                        margin-top: 1rem;">
                            <span translate>
                                VER MAS
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div> -->