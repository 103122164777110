import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { API } from 'src/app/constants/api';
import { OnboardService } from 'src/app/services/onboard.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dialog-auth',
  templateUrl: './dialog-auth.component.html',
  styleUrls: ['./dialog-auth.component.css']
})
export class DialogAuthComponent implements OnInit {

  register: boolean

  name: string
  email: string
  password: string
  confirmPassword: string
  loading: boolean = false
  loaderText: string = ''
  currentUser: any;
  resolvedCaptcha!: string;

  constructor(
    public dialogRef: MatDialogRef<DialogAuthComponent>,
    @Inject(MAT_DIALOG_DATA) 
    public wallet: string,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private onboardService: OnboardService
  ) { 
    this.register = false
    this.email = ''
    this.password = ''
    this.name = ''
    this.confirmPassword = ''
  }

  ngOnInit(): void {
    this.userService.user.subscribe((user) => {
      this.currentUser = user;
    });
  }

  registerWallet(wallet:string) {

    this.loading = true;
    this.userService.getChallenge(String(wallet).toLowerCase()).subscribe(async resultChallenge => {
      if (!resultChallenge) {
        this.loading = false;
        this.snackBar.open(this.translate.instant('Error getting Challenge'), '');
        return;
      }
      let resultChallengeSigned;
      try {
        resultChallengeSigned = await this.onboardService.signChallenge(resultChallenge);
      }
      catch (err) {
        console.error(err);
        this.snackBar.open(this.translate.instant('Error signing Challenge'), '', { duration: 2000 });
        this.loading = false;
        return;
      }

      if (!resultChallengeSigned) {
        this.loading = false;
        this.snackBar.open(this.translate.instant('Error getting Challenge'), '', { duration: 2000 });
        return;
      }
      this.loading = false;
      this.loaderText = this.translate.instant('Wait for a moment, we are importing your images from dgalery.com Wallet');
    
      if(this.name == '' || this.email == '' || this.password == '' || this.confirmPassword == '') {
        this.snackBar.open(this.translate.instant('Please fill all the fields'), '', { duration: 2000 });
        return;
      }
      if (this.password != this.confirmPassword) {
        this.snackBar.open(this.translate.instant('Passwords do not match'), '', { duration: 2000 });
        return;
      }
      if (!this.resolvedCaptcha) {
        this.snackBar.open(this.translate.instant('Please resolve the captcha'), '', { duration: 2000 });
        return;
      }
      this.loading = true;

      const body = {
        wallet: wallet,
        username: this.name,
        password: this.password,
        email: this.email,
        captcha: this.resolvedCaptcha
      }
      this.userService.signupWallet(body).subscribe({
        next: (response: any) => {
          this.loading = false;
          localStorage.setItem('token', response.token);
          this.userService.refreshUser();
          this.dialogRef.close();
          this.snackBar.open(this.translate.instant('Register Successful'), "", { duration: 2000 });
          
        },
        error: (error: any) => {
          this.loading = false;
          this.snackBar.open(this.translate.instant('Error occurred please try again later'), '', { duration: 2000 });
        }
      }) 

    }); 

}
  



  loginDGalery() {
    const params = {wallet: this.wallet, email: this.email, password: this.password} 
    //console.log(params); 
    localStorage.removeItem('token');
    this.loaderText = this.translate.instant('Wait a moment, we are importing your gallery');
    this.loading = true;
    const user = this.currentUser;
    this.userService.loginDGalery(params).subscribe({
      next: async (response: any)  => {
        this.loading = false;
        await localStorage.setItem('token', response.token)
        this.userService.refreshUser();
        const loginUser = await this.userService.getUser();
        this.userService.updateImageProfile(`${API.dgalery_url}/static/uploads/profile-${loginUser.idDgalery}.png?t=${Date.now()}`);
        this.snackBar.open(this.translate.instant('Login Successful'), "", { duration: 2000 });
        this.dialogRef.close();
      },
      error: (error: any) => {
        this.loading = false;
        localStorage.removeItem('token');
        this.snackBar.open(this.translate.instant('Error occurred please try again later'), '', { duration: 2000 });
      }
    })
  }

  resolved(captchaResponse: string) {
    this.resolvedCaptcha = captchaResponse;
  }


}
