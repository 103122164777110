import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { Router } from "@angular/router";
import { CollectionService } from "src/app/services/collection.service";
import { ItemService } from "src/app/services/item.service";
import { config } from "src/app/constants/config";
import { FaqItem } from "./faq-item/faq-item.component";
import { EmailService } from "src/app/services/email.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { DialogService } from "src/app/services/dialog.service";
import { OnboardService } from "src/app/services/onboard.service";

declare var $: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  showVideo: boolean = false;
  mediaBase: string = config.media_path;
  currentUser: any;
  collectionMenu = "";
  collections: any = [];
  hotCollections: any = [];
  collectionItems: any = [];
  collectionItemsSubastas: any = [];
  trendingItems: any = [];
  trendingMysteryBox: any = [];
  loading = true;
  isSending: boolean = false;

  loadingMysteryBox = true;
  categories: any = [];
  bestSellers: any = [];
  sellMethod: any;
  tokenId: any;
  faqs: FaqItem[] = [
    { question: "Fquestion1", answer: "Fanswer1", showAnswer: false },
    { question: "Fquestion2", answer: "Fanswer2", showAnswer: false },
    { question: "Fquestion3", answer: "Fanswer3", showAnswer: false },
    { question: "Fquestion4", answer: "Fanswer4", showAnswer: false },
    { question: "Fquestion5", answer: "Fanswer5", showAnswer: false },
    { question: "Fquestion6", answer: "Fanswer6", showAnswer: false },
    { question: "Fquestion7", answer: "Fanswer7", showAnswer: false },
  ];
  name!: string;
  email!: string;
  message!: string;
  resolvedCaptcha!: string;
  communityArt: any = [];
  adminArt:any = [];
  listTopSellers: any = [];

  constructor(
    private userService: UserService,
    private router: Router,
    private collectionService: CollectionService,
    private itemService: ItemService,
    private emailService: EmailService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialogService: DialogService,
    private onboardService: OnboardService,
  ) {
    this.userService.user.subscribe((user) => {
      this.currentUser = user;
    });
  }

  ngOnInit(): void {
    this.itemService.getCommunityMarket(undefined,4).subscribe((result) => {
      //const tempArray = result.data;
      this.communityArt = result.data.sales;
    });

    this.itemService.getAdminMarketplace(undefined,8).subscribe((result) => {
      //const tempArray = result.data;
      this.adminArt = result.data.sales;
    });


    this.itemService.getAuctions(1,4).subscribe((result) => {
      //console.log(result.data);
      this.collectionItemsSubastas = result.data.sales;
    
    }, (error) => {
    });
    this.topSellers()
  }

  onSubmit() {
    if (!this.name || !this.email || !this.message) {
      this.snackBar.open(
        this.translate.instant("Please fill all the fields"),
        "",
        { duration: 2000 }
      );
      return;
    }

    if (!this.isValidEmail(this.email)) {
      this.snackBar.open(
        this.translate.instant("Please enter a valid email address"),
        "",
        { duration: 2000 }
      );
      return;
    }

    if (!this.resolvedCaptcha) {
      this.snackBar.open(
        this.translate.instant("Please solve the captcha"),
        "",
        { duration: 2000 }
      );
      return;
    }

    this.isSending = true;
    this.emailService
      .sendEmail(this.name, this.email, this.message, this.resolvedCaptcha, this.translate.instant("language"))
      .subscribe(
        (response) => {
          this.snackBar.open(
            this.translate.instant("Message sent successfully"),
            "",
            { duration: 2000 }
          );
        },
        (error) => {
          this.snackBar.open(
            this.translate.instant("Message was not sent, an error happened"),
            "",
            { duration: 2000 }
          );
          this.isSending = false;
        }
      );
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/i;
    return emailRegex.test(email);
  }


  topSellers(){
    this.userService.getTopSellers().subscribe({
      next: (result: any) => {
        this.listTopSellers = result.data.sort( (a:any, b:any) => {
          if (a.profit > b.profit) {
            return -1;
          }
          if (a.profit < b.profit) {
            return 1;
          }
          // a must be equal to b
          return 0;
        });
      },
      error: (error: any) => {
      }
    });
  }


  openDialogCumnity() {
    this.dialogService.openDialogCustom({
      title: "comunity modal title",
      content: "comunity modal content",
      btnText: "close"
    });
  }

  resolved(captchaResponse: string) {
    this.resolvedCaptcha = captchaResponse;
  }
}
