<div class="menu" style="display: none">

  <header class="transparent" [ngClass]="{'header-light scroll-light': apply_dark_theme == false}" style=" padding: 1rem;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="de-flex sm-pt10">
            <div class="de-flex-col">
              <div class="de-flex-col">
                <!-- logo begin -->
                <div id="logo">
                  <a routerLink="/">
                    <img alt="" class="logo2" src="./../assets/images/logosvg.webp" style="width: 200px;">
                  </a>
                </div>
                <!-- logo close -->
              </div>
            </div>
            <div class="de-flex-col header-col-mid">
              <!-- mainmenu begin -->
              <ul id="mainmenu">
                <li [ngClass]="{'active': current_page == ''}">
                  <a routerLink="/" href="javascript:void(0)" (click)="collapseMenu()" translate>Home</a>
                </li>
                <li [ngClass]="{'active': current_page == 'marketplace'}">
                  <a href="javascript:void(0)" [routerLink]="['/marketplace']" >Marketplace</a>
                  <ul>
                    <li><a href="javascript:void(0)" [routerLink]="['/marketplace']" translate>All NFTs</a></li>
                    <li *ngFor="let item of categoriesLimited">
                      <a href="javascript:void(0)" [routerLink]="['/marketplace']"
                        [queryParams]="{category_id: item._id}">{{item.title}}</a>
                    </li>
                  </ul>
                </li>
                <li [ngClass]="{'active': current_page == 'stats'}">
                  <a routerLink="/stats" href="javascript:void(0)" (click)="collapseMenu()" translate>Activity</a>
                </li>
                <li *ngIf="user!=null">
                  <a [routerLink]="'/collection/profile/' + user.id" href="javascript:void(0)" (click)="collapseMenu()" translate>My Profile</a>
                </li>
                <li [ngClass]="{'active': current_page == 'login'}" *ngIf="user!=null">
                  <a href="javascript:void(0)">
                    <img [src]="imageProfile" class="profileImage" alt="" (error)="handleImageError($event)" />
                      {{user.username}}
                   </a>
                  <ul>
                    <li><a href="javascript:void(0)" [routerLink]="'/collection/profile/' + user.id"
                        (click)="collapseMenu()" translate>My Profile</a></li>
                    <li><a href="javascript:void(0)" [routerLink]="'/profile/collected'"  (click)="collapseMenu()"
                        translate>My Collection</a></li>
                    <!-- <li><a href="javascript:void(0)" [routerLink]="'/profile/favorited'"
                        (click)="collapseMenu()" translate>My Favorites</a></li> -->
                        <li><a href="http://app.dgalery.com/" target="_blank"
                          (click)="collapseMenu()" translate>Create an image</a></li>
                    <li><a href="javascript:void(0)" #btnLogout id="linkLogout" (click)="logoutAction()" (click)="collapseMenu()"
                        translate>Logout</a></li>
                  </ul>
                </li>

                <li class="unique-li" style="padding: 15px 10px;" *ngIf="user">
                  <div style="cursor: pointer !important;">
                    <img src="./../assets/images/notification.png" alt="" class="searchIcon" />
                    <div class="d-flex justify-content-center align-items-center" *ngIf="notificationCount > 0"
                    style="position: absolute; top: 15px; left: 25px; border-radius: 30px; 
                    background-color: #ff004f; width: 15px; height: 15px;">
                      <p style="color: white; font-size: 10px; position: relative; top: 9px;">{{notificationCount}}</p>
                    </div>
                  </div>
                  <ul class="unique-ul" style="width: 190px;">
                    <li *ngFor="let notification of notificationsList">
                      <a href="javascript:void(0)"  class="d-flex align-items-center" (click)="viewNotification(notification)">
                        <img [src]="mediaBase + '/images/' + notification.url" class="profileImage" alt="" />
                        {{notification.type | translate }}
                        <!-- <span *ngIf="notification.type == 'auction'">Auction ended</span>
                        <span *ngIf="notification.type == 'purchase'">Sold</span>
                        <span *ngIf="notification.type == 'bids'" style="color: black">Bid</span> -->
                      </a>
                    </li>
                  </ul>
                </li>
  
                <li>
                  <a href="javascript:void(0)">
                    <em class="flag-icon flag-icon-{{current_lang}}"></em>
                    <label class="flag-pl5"> {{current_lang | translate}}</label>
                  </a>
                  <ul class="flag-custom-label">
                    <li>
                      <a href="javascript:void(0)" (click)="changeLang(lang)" class="flag-alt"
                        *ngFor="let lang of availableLangs()">
                        <em class="flag-icon flag-icon-{{lang}}"></em>
                        <label style="color: black!important;" class="flag-pl5"> {{lang | translate}}</label>
                      </a>
                    </li>
                  </ul>
                </li>

                <!-- until here -->
              </ul>

              <div class="menu_side_area">
                <a href="javascript:void(0)" #btnConnecWallet id="linkConnecWallet" (click)="connectWallet()" class="newbtn btnsize1 hide-mobil" *ngIf="user==null">Wallet</a>
                <a [routerLink]="['/profile/created']" class="btn-main btn-coll-add hide-mobil"   *ngIf="user!=null" translate>My galery</a>
                <span id="menu-btn"></span>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  
  
  <router-outlet></router-outlet>
  
  
  <footer class="text-white text-center text-lg-start" style="background-color: #111111; padding-top: 0px;">
    <!-- Grid container -->
    <div class="p-4">
      <!--Grid row-->
      <div class="row mt-4">
        <!--Grid column-->
        <div class="col-lg-3 col-md-12 mb-4 mb-md-0 text-center p-4">
          <div class="d-block align-items-center justify-content-center mb-4 mx-auto" style="width: 45vh; height: 66px;">
            <img src="./../assets/images/logofooter.png" height="66" alt=""
                 loading="lazy" />
            <div class="d-block mt-2">
              <p translate>Footer Info2</p>
            </div>
          </div>
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="col-lg-3 col-md-6 mb-4 mb-md-0 text-center" *ngIf="user">
  
          <div class="widget">
            <h5  class="text-uppercase mb-4 pb-1" translate="">My Account</h5>
            <ul>
              <li><a [routerLink]="'/collection/profile/' + user?.id" translate>My Profile</a></li>
              <li><a [routerLink]="['/profile/collected']" translate>Collections</a></li>
              <li><a href="https://app.dgalery.com/" target="_blank" translate>Create</a></li>
              <!-- <li><a [routerLink]="['/marketplace']" translate>Settings</a></li> -->
              <!-- <li *ngFor="let item of categories"><a [routerLink]="['/marketplace']"
                  [queryParams]="{category_id: item._id}">{{item.title}}</a></li> -->
            </ul>
          </div>
        </div>
        <!--Grid column-->      
        <!--Grid column-->
        <div class="col-lg-3 col-md-6 mb-4 mb-md-0 text-center">
          
          <div class="widget">
            <h5  class="text-uppercase mb-4 pb-1">Marketplace</h5>
            <ul>
              <li><a [routerLink]="['/marketplace']" [queryParams]="{collection: 'communityArt'}" translate>Community NFTs</a></li>
              <li><a [routerLink]="['/marketplace']" [queryParams]="{collection: 'DGalery'}" translate>Dgalery NFT</a></li>
              <li><a [routerLink]="['/marketplace']" [queryParams]="{category: 'Auctions'}" translate>Hot Auctions</a></li>
              <li><a [routerLink]="['/marketplace']" translate>All Nft</a></li>
              <!-- <li *ngFor="let item of categories"><a [routerLink]="['/marketplace']"
                  [queryParams]="{category_id: item._id}">{{item.title}}</a></li> -->
            </ul>
          </div>
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="col-lg-3 col-md-6 mb-4 mb-md-0 text-center">
          <div class="widget">
            <h5 class="text-uppercase mb-4 pb-1" translate>Legal</h5>
            <ul>
              <li><button (click)="openDialogLegalWarnings()" class="legacy__buttons" translate>Legal warning</button></li>
              <li><button (click)="openDialogPrivacyPolicy()" class="legacy__buttons" translate>Privacy Policy</button></li>
              <li><button (click)="openDialogCookies()" class="legacy__buttons" translate>Cookies Policy</button></li>
  
              <!-- <li><a href="#" translate>Legal warning</a></li>
              <li><a href="#" translate>Privacy Policy</a></li>
              <li><a href="#" translate>Cookies Policy</a></li> -->
            </ul>
          </div>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
      <div style="display: flex; justify-content: center;">
        <p translate>Footer Info1</p>
      </div>
    </div>
  
    <!-- Grid container -->
  </footer>
  
  </div>

  <app-loader *ngIf="spinner"></app-loader>
  <app-for-loader-srv>
  
