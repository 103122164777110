import { Injectable, Output, EventEmitter } from '@angular/core';
//import { EventEmitter } from 'stream';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublishItemService {
  //@Output() dataItem: EventEmitter<any> = new EventEmitter();
  /*
  dataItem: EventEmitter<any> = new EventEmitter();

  publishDataItem(item: any) {
    this.dataItem.emit(item);
  }*/

  private dataItem = new BehaviorSubject<any>(null);
  currentDataItem = this.dataItem.asObservable();
  currenteUserItem = this.dataItem.asObservable();

  sendDataItem(item: any, userImage: string) {
    const data = {item, userImage};
    this.dataItem.next(data);
  }
}
