import {Deserializable} from "./deserializable";
export class Category implements Deserializable{
    _id:any;
    title:any;
    category_image:any;

    deserialize(input: any) {
      Object.assign(this, input);
      return this;
    }
}
