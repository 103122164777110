<div class="profileContainer" 
    #profileContainer >
    <div class="topContainer" #topContainer style="background-color: white;">
        <div class="profileHeader">
            <div class="profileCover">
                <img [src]="imageBanner" style="object-fit: cover !important;">
                 <div class="editAction" > <!-- *ngIf="ownProfile" -->
                    <button class="btn btn-secondary"><i class="icon-note"></i></button>
                    <input type="file" (change)="onFileSelected($event, 'img-banner')" multiple />
                </div>
            </div>
            <div class="profileAvatar">
                <div class="profileAvatarInner">
                  <img [src]="imageProfile" (error)="handleImageError($event)">
                  <div class="avatarAction">
                    <i class="icon-pencil"></i>
                    <input type="file" (change)="uploadProfileImage($event)" accept=".png" />
                  </div>
                </div>
            </div>
        </div>
        <div class="profileSubHeader" *ngIf="currentUser!=null">
            <h4 style="color: black!important;"> {{currentUser.username.toLowerCase()}}</h4>
            <p (click)="copyAddress()">
                {{currentUser.wallet.substring(0,4)}}...{{currentUser.wallet.substring(currentUser.wallet.length-4)}}<i
                    class="fa fa-copy"></i></p>
        </div>
        <div>
  
            <div class="tabmenu-1 container"> 
                <ul>
                    <li (click)="profileLink('collected')" class="tab-item active"  [ngClass]="{'active': type=='collected'}"><a class="options-title" href="javascript:void(0);"
                            ><i class="cil-basket"></i><span 
                                translate>Collected</span></a></li>
                    <li (click)="profileLink('created')" class="tab-item" [ngClass]="{'active': type=='created'}"><a class="options-title" href="javascript:void(0);"
                            ><i class="cil-note-add"></i><span 
                                translate>Created</span></a></li>
                    <!-- <li  (click)="profileLink('favorited')" class="tab-item"  [ngClass]="{'active': type=='favorited'}"><a class="options-title" href="javascript:void(0);"
                           ><i class="cil-heart"></i><span 
                                translate>Favorites</span></a></li> -->
                    <li (click)="profileLink('activity')"  class="tab-item" [ngClass]="{'active': type=='activity'}"><a class="options-title" href="javascript:void(0);"
                            ><i class="cil-bar-chart"></i><span
                                 translate>Activity</span></a></li>
                </ul>
            
           </div>

                <div class="container-fluid">
                    <router-outlet></router-outlet>
                </div>
        </div>
    </div>


</div>
<app-loader *ngIf="spinner" loadingText="{{loaderText}}"></app-loader>
