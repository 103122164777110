import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { config } from 'src/app/constants/config';
import { UserService } from 'src/app/services/user.service';
import * as moment from 'moment';
import { CountdownConfig } from 'ngx-countdown';
import { ItemService } from 'src/app/services/item.service';
import { OnboardService } from 'src/app/services/onboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { API } from '../../constants/api';
import { PublishItemService } from 'src/app/services/publish-item.service';
import { error } from 'console';
import { LoaderService } from 'src/app/services/loader.service';

const CountdownTimeUnits: Array<[string, number]> = [
  ['Y', 1000 * 60 * 60 * 24 * 365], // years
  ['M', 1000 * 60 * 60 * 24 * 30], // months
  ['D', 1000 * 60 * 60 * 24], // days
  ['H', 1000 * 60 * 60], // hours
  ['m', 1000 * 60], // minutes
  ['s', 1000], // seconds
  ['S', 1], // million seconds
];

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {
  @Input() item: any;
  @Input() user: any;
  @Input() pathName: any;
  @Input() displayActions = false;
  @Output() itemEvent = new EventEmitter<any>();
  now: number;
  cdConfig: CountdownConfig;
  mediaBase: string = config.media_path;
  baseUrl: string = API.url;
  currentUser: any;
  spinner = false;

  converted_prices = {
    usd: 0
  };

  oldView: boolean = false;

  isVideo: boolean = false;

  constructor(
    private itemService: ItemService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private onboardService: OnboardService,
    private router: Router,
    private publishItemService: PublishItemService,
    private loaderService: LoaderService
  ) {
    
    this.now = moment().unix();
    this.cdConfig = {
      leftTime: 0
    };
    this.userService.user.subscribe((user) => {
      this.currentUser = user;
    });
  }

  ngOnInit(): void {
    /*
    const file_extencion = this.item.thumb.split(".");
    if(file_extencion[file_extencion.length-1] == "mp4"){
      this.isVideo = true;
    }
    this.cdConfig = {
      leftTime: this.timeLeft(),
      formatDate: ({ date, formatStr }) => {
        let duration = Number(date || 0);

        return CountdownTimeUnits.reduce((current, [name, unit]) => {
          if (current.indexOf(name) !== -1) {
            const v = Math.floor(duration / unit);
            duration -= v * unit;
            return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
              return v.toString().padStart(match.length, '0');
            });
          }
          return current;
        }, formatStr);
      },
      
    }; */

    // setTimeout(() => {
    //   this.convert_price('MATIC', this.item.price, 'USD');
    // }, 800);
    //console.log('this.item', this.item);
    //console.log(this.pathName);
    //console.log(this.item.tokenId);
  }

  // convert_price = (symbol: string, amount: number, convert: string) => {
  //   this.itemService.convert_price({
  //     symbol,
  //     amount,
  //     convert
  //   }).subscribe(async result => {
  //     this.converted_prices.usd = result.data.quote.USD.price.toFixed(4);
  //   });
  // }

  /**
   * This is the function which used send notification to parent component for item view
   */


  /**
   * This is the function which used send notification to parent component for item edit
   */
  viewAction = () => {
    if(this.item?.hasOwnProperty('orderId') === true) {
      this.itemEvent.emit({
        type:"view",
        item:this.item
      });
    }
  }


  viewItem(id: any){
    //console.log(id);
    this.router.navigate(['/item/view', id]);

  }

  /**
   * This is the function which used send notification to parent component for item edit
   */
  editAction = () => {
    this.itemEvent.emit({
      type:"edit",
      item:this.item
    });
  }

  /**
   * This is the function which used send notification to parent component for item delete
   */
  deleteAction = () => {
    this.itemEvent.emit({
      type:"delete",
      item:this.item
    });
  }

  /**
   * This is the function which used send notification to parent component for item publish
   */
  publishAction = () => {
    this.item
    //console.log('this.item', this.item);
    if(this.item.token_id  && this.item.hasOwnProperty('orderId') === false) { 
      this.publishItemToSell();
    } else {
      this.publishItem();
    }
    
  }

  //publish(prompt: string, image: string, createdAt: string) {
  publish(item:any, userImage: string) {
    this.publishItemService.sendDataItem(item, userImage);
    this.router.navigate(['/collection/add']);
    
  }

  downloadItem = () => {
    this.loaderService.show();
    this.itemService.downloadImageNFT({imageId: this.item.imageId}).subscribe((result) => {
      this.loaderService.hide();
      window.location.reload();
    }, error => {
      //console.log('error:', error);
    
    });
  }
  collectedItem = () => {

    /*
    if (this.item.onSale) this.router.navigate(['/item/view/' + this.item.tokenId]);
    else this.publish(this.item, this.user)*/

    this.router.navigate(['/item/view/' + this.item.tokenId]);

  }

  author = () => {
    const profile_image = this.item.collection_id?.author_id?.profile_image != "" ? this.item.collection_id?.author_id?.profile_image : 'nouser.jpg';
    return this.mediaBase + '/images/user/' + profile_image;
  }

  authorFullname = () => {
    return  `${this.item.collection_id?.author_id?.first_name} ${this.item.collection_id?.author_id?.last_name}`;
  }

  timeLeft = () => {
    if (this.item?.sell_method === 2) {
      if (this.now < this.item?.expire_at) {
        return this.item?.expire_at - this.now + 133;
      }
    }
    return 0;
  }

  isOwner = () => this.item?.author_id === this.currentUser?.user_id;

  isOrderExist = () => this.item?.hasOwnProperty('orderId') === false

  publishItem = () => {
    const itemId = this.item._id;
    this.spinner = true;
    this.userService.viewProfile(this.currentUser.user_id).subscribe(res => {
      var resulter: any = res;
      //console.log(resulter.result.public_key)
    this.itemService.getDataToDeploy(itemId).subscribe(async (result) => {
      //console.log('', result);
      // return;
      this.spinner = false;
      //console.log('result getDataToDeploy:', result);
      // TODO: validate expire_at field before publish item

      if (!result.status) {
        this.snackBar.open('Ha ocurrido un error al obtener los datos para mintear el token', '', { duration: 2000 });
        return;
      }

      if (!await this.onboardService.checkAccount()) { return; }

      this.spinner = true;
      const contractData = {
        operator: result.data.operator,
        address: result.data.nft_smart_contract.address,
        abi: result.data.nft_smart_contract.abi,
        addressOwner:  resulter.result.public_key,
        // bin: `${result.data.nft_smart_contract.bin}`,
        // tokenRoyalty: result.data.royalties,
        // tokenName: `${itemId}`, // result.data.name,
        // tokenSellMethod: result.data.sell_method,
        // tokenExpireAt: result.data.expire_at,
        // collection_id: itemInfo.collection_id._id,
        uri: result.data.link_metadata
      };

      await this.onboardService.mintToken(contractData)
        .then((mintResult: any) => {
          this.spinner = false;
          // the result holds your Token Balance that you can assign to a var
          //console.log('mint: ', mintResult);
          // var myTokenBalance = result;
          // return result;
          this.itemService.syncMintItem({
            item_id: itemId,
            transaction_hash: mintResult.transactionHash,
            token_id: mintResult.events.tokenCreation.returnValues.tokenId,
            gasUsed: mintResult.gasUsed,
            cumulativeGasUsed: mintResult.cumulativeGasUsed,
            block: mintResult.blockNumber,
            from: mintResult.from,
            to: mintResult.to,
          }).subscribe(async (syncMintItemResult) => {


         

            const approve = await this.onboardService.getApprovedForAll(contractData);
            //console.log('approve', approve);
            if(approve === true){ 
              this.publishItemToSell();

            } else {
              const itemId = this.item._id;
              this.spinner = true;
              this.itemService.getDataToDeployMarket(itemId).subscribe(async (res) => {
                // return;
                this.spinner = false;
                //console.log('result getDataToDeploy:', res);
                // TODO: validate expire_at field before publish item
          
                if (!res.status) {
                  this.snackBar.open('Ha ocurrido un error al obtener los datos para mintear el token', '', { duration: 2000 });
                  return;
                }
          
                if (!await this.onboardService.checkAccount()) { return; }
          
                this.spinner = true;
                const approveContract = {
                  address: res.data.smart_contract_market.address,
                  abi: contractData.abi,
                }
  
                const contractData2 = { 
                  addressNft: contractData.address
                }
          
                await this.onboardService.setApprovalForAll(approveContract, contractData2).then((approveMarketResult: any) => {
                  //console.log('approveMarketResult:', approveMarketResult);
                      this.publishItemToSell();
                }).catch(e => {
                  //console.log('approveMarketResult Error:', e);
                  this.snackBar.open(e.message, '', { duration: 5000 });
                  this.spinner = false;
                });
              });
            }

          

         
            // this.router.navigate(['/item/view/' + itemId]);
          });

          // //console.log('result mint > :', mint);
        }).catch(e => {
          //console.log('approveMarketResult Error:', e);
          this.snackBar.open(e.message, '', { duration: 5000 });
          this.spinner = false;
        });
    });
  })
  };

  publishItemToSell = () => {
    const itemId = this.item._id;
    this.spinner = true;
    this.itemService.getDataToDeployMarket(itemId).subscribe(async (result) => {
      //console.log('', result);
      // return;
      this.spinner = false;
      //console.log('result getDataToDeploy:', result);
      // TODO: validate expire_at field before publish item

      if (!result.status) {
        this.snackBar.open('Ha ocurrido un error al obtener los datos para mintear el token', '', { duration: 2000 });
        return;
      }

      if (!await this.onboardService.checkAccount()) { return; }

      this.spinner = true;
      const contractData = {
        operator: result.data.operator,
        address: result.data.smart_contract_market.address,
        abi: result.data.smart_contract_market.abi,
        tokenId: result.data.token_id,
        // tokenRoyalty: result.data.royalties,
        // tokenName: `${itemId}`, // result.data.name,
        // collection_id: itemInfo.collection_id._id,
        tokenPrice: result.data.price,
        tokenSellMethod: result.data.sell_method,
        tokenExpireAt: result.data.expire_at,
        tokenRoyalty: result.data.royalties,

      };

     

      await this.onboardService.tokenOnSell(contractData)
        .then((mintResult: any) => {
          this.spinner = false;
          // the result holds your Token Balance that you can assign to a var
          //console.log('mint: ', mintResult);
          // var myTokenBalance = result;
          // return result;
        
            this.spinner = false;


            setTimeout(() => {
              this.router.navigate(['/item/view/' + itemId]);
              
            }, 1000);
        
          // //console.log('result mint > :', mint);
        }).catch(e => {
          //console.log('approveMarketResult Error:', e);
          this.snackBar.open(e.message, '', { duration: 5000 });
          this.spinner = false;
        });
    });
  };
  
@ViewChild('image') image!: ElementRef;

onImageLoad(event:any) {
  const width = event.target.offsetWidth;
  this.image.nativeElement.style.height = `${width}px`;
}


}
