<div class="p-5 rounded"
style="background-color: #161616; width: 80vh; filter: drop-shadow(0px 0px 10px #ff004f);">
    <h2 mat-dialog-title class="title" translate>Change sell order</h2>
    <p translate>Change the price of your NFT</p>
    <div class="close-btn" (click)="closeDialog()"><i class="fa fa-times fa-2x" aria-hidden="true"></i></div> 
    <div class="contact-form-input">
        <input
          type="text"
          class="form-control rounded border-white form-input mb-2"
          style="background: transparent; color: white"
          name="price"
          placeholder="Enter new price"
          required
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          [(ngModel)]="price"
        />
      </div>
    
      <div class="contact-form-input" *ngIf="data.sellMethod == 2">
        <label for="auction-time" translate>Auction time</label>
        <select class="custom-select" [(ngModel)]="auctionTime" (change)="calculateExpiryDate()" style="background: transparent;">
            <option value="" disabled selected>Select an option</option>
            <option *ngFor="let date of timeForAuction" [value]="date.value">{{date.days|translate}}</option>
        </select>
        <p *ngIf="expirationDate">{{'The auction will expire on '|translate}}{{expirationAuction | date: getDateFormat()}}</p>
      </div>

      <div class="d-flex justify-content-center align-items-center py-2 mt-2">
    <button style="    
    background: #FF004F;
    border: none;
    font-weight: bold;
    padding: 8px 29px;
    color: white!important;"
    (click)="changeOrder()"
    translate
    >Save</button>
    </div>

</div>