import { Component, OnInit } from '@angular/core';
import { config } from 'src/app/constants/config';
import { CollectionService } from 'src/app/services/collection.service';
import { ItemService } from 'src/app/services/item.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, BehaviorSubject } from "rxjs";
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { error } from 'console';
import { min } from 'moment';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.css']
})
export class MarketplaceComponent implements OnInit {
  subscription:any;
  isToggled:boolean = true;
  isOfferCollapsed:boolean = false;
  isPriceCollapsed:boolean = false;
  isCollectionCollapsed:boolean = true;
  isCategoryCollapsed:boolean = true;

  collectionSearchInput:string = ""
  searchRequest:any;
  collectionPage:any = 1;
  collections:any = [];
  mediaBase: string = config.media_path;
  categories:any = [];

  searchInput:string = ""
  items:any = [];
  page:any = 1;
  isApiLoading:boolean = false;
  loading:boolean = true
  keyword:string = ""
  type:string = "sort"
  value: string = "recent"
  currency: string = "";
  sellMethod: any;
  tokenId: any;
  orderId : any;
  // page_title: string = "Recent Products"

  includemysteryboxes = false;

  min_price: any = null;
  max_price: any = null;

  filterActive: boolean
  filterActiveStyle: string

  totalPage: number = 1;
  currentPage: number = 1;
  minPrice?: number
  maxPrice?: number 
  firstSearch: any;
  noDataMessage: string = "There isn't data related to your search";
  private pageFunction: (page: number) => void;
  // private pageTitleSubject: BehaviorSubject<any>;
  // public pageTitle: Observable<any>;

  constructor(
    private collectionService : CollectionService,
    private itemService: ItemService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.categories = [
      {
        id: 1,
        title: "Auctions",
      },
      {
        id: 2,
        title: "Simple sales"
      }
    ]
    /*
    this.searchCollectionAction();
    this.getCategories();
    this.pageTitleSubject = new BehaviorSubject<string>("Recent Products");
    this.pageTitle = this.pageTitleSubject.asObservable();
    this.commonService.currentSetting.subscribe(setting => this.currency = setting.currency);*/
    this.filterActive = false;
    this.filterActiveStyle = "col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12";
    this.getMarket(1);
    this.pageFunction = this.getMarket;
   }

  ngOnInit(): void {
     this.subscription = this.route.queryParams.subscribe(params => {
      this.currentPage = 1;

       
       if (params.q) {
         this.keyword = this.searchInput = String(params.q).replace(" ", "+")
         this.type = "sort";
         //return this.getItemList();
       }

       /*
       if(params.type) {
          this.type = "sort";
          this.value = params.type
         if (params.type == "offer") {
           this.pageTitleSubject.next(this.translate.instant("Product with offer"));
           //  this.page_title = "Product with offer"
         } else if (params.type == "mostviewed") {
           this.pageTitleSubject.next(this.translate.instant("Most viewed product"));
           //  this.page_title = "Most viewed product"
         } else if (params.type == "mostliked") {
           this.pageTitleSubject.next(this.translate.instant("Most liked product"));
           //  this.page_title = "Most liked product"
          } else {
            this.pageTitleSubject.next(this.translate.instant("Recent product"));
           //  this.page_title = "Recent product"
          }
       }*/

       if(params.min && params.max){
         this.minPrice = params.min;
         this.maxPrice = params.max;
         this.getMarket(1);
       }
       if(params.price) {
        this.minPrice = 1;
        this.maxPrice = params.price;
        this.getMarket(1);
         //this.pageTitleSubject.next(this.translate.instant("products less than or equal to" + this.value + " ETH"));
         // this.page_title = "Products greater than " + this.value + " ETH"
       }
       if(params.category) {
        switch (params.category) {
          case "Auctions":
            this.getAuctions(1);
            break;
          case "Simple sales":
            this.getSimpleSales(1);
            break;
        }
        return;
       }


       if(params.collection) {
        switch (params.collection) {
          case "DGalery":
            this.getAdminArt(1);
            break;
          case "communityArt":
            this.getCommunityArt(1);
            break;
        }
        return;
       }


     });
  }

   ngOnDestroy() {
     this.subscription.unsubscribe()
   }


  getMarket(page:number) {
    this.loading = true;
    this.itemService.getMarketplace(page,8,this.minPrice,this.maxPrice).subscribe(result => {
      //console.log(result.data);
      if (!this.firstSearch && !this.minPrice && !this.maxPrice) this.firstSearch = result.data;  
      this.totalPage = result.data.totalPages;
      this.minPrice = result.data.minPrice;
      this.maxPrice = result.data.maxPrice;
      this.pageFunction = this.getMarket;
      this.items = result.data.sales;
      this.loading = false;
    }, error => {
      //console.log(error);
      if (!this.minPrice && !this.maxPrice) this.noDataMessage = "There is no data to display here";
      this.items = [];
      this.loading = false;
    
    })
  }

  getAuctions(page:number) {
    this.loading = true;
    this.itemService.getAuctions(page,8).subscribe({
      next: (result) => {
        //console.log(result.data);
        this.totalPage = result.data.totalPages;
        this.items = result.data.sales; 
        this.pageFunction = this.getAuctions;
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        //console.log(error);
        this.items = [];
      }
    })

  }

  getSimpleSales(page:number) {
    this.loading = true;
    this.itemService.getSimpleSales(page,8).subscribe({
      next: (result) => {
        //console.log(result.data);
        this.totalPage = result.data.totalPages;
        this.items = result.data.sales; 
        this.pageFunction = this.getSimpleSales;
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        //console.log(error);
        this.items = [];
      }
    })
  
  }

  getCommunityArt(page:number) {
    this.loading = true;
    this.itemService.getCommunityMarket(page,8).subscribe({
      next: (result) => {
        //console.log(result.data);
        this.totalPage = result.data.totalPages;
        this.items = result.data.sales; 
        this.pageFunction = this.getCommunityArt;
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        //console.log(error);
        this.items = [];
      }
    })
  }

  getAdminArt(page:number) {
    this.loading = true;
    this.itemService.getAdminMarketplace(page,8).subscribe({
      next: (result) => {
        //console.log(result.data);
        this.totalPage = result.data.totalPages;
        this.items = result.data.sales; 
        this.pageFunction = this.getAdminArt;
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        //console.log(error);
        this.items = [];
      }
    })
  }

  nextPage() {
    if (this.currentPage < this.totalPage) {
      this.currentPage = this.currentPage + 1;
      this.pageFunction(this.currentPage);
    }
  }

  previusPage() {
    if (this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
      this.pageFunction(this.currentPage);
    }
  }

  cleanSearch(){
    this.minPrice = undefined;
    this.maxPrice = undefined;
    this.searchInput = "";
    if(this.firstSearch) {
      //console.log('se utilizo el respaldo');
      //console.log(this.firstSearch);
      this.items = this.firstSearch.sales;
      this.currentPage = 1;
      this.totalPage = this.firstSearch.totalPages;
      this.pageFunction = this.getMarket;
    } else {
      this.getMarket(1);
    }    
}
  searchInMarket() {
    this.loading = true;
    this.itemService.searchInMarketplace(this.keyword,this.currentPage).subscribe({
      next: (result) => {
        //console.log(result.data);
        this.items = result.data.sales;
        this.loading = false;
        this.currentPage = result.data.currentPage;
        this.totalPage = result.data.totalPages;
        this.pageFunction = this.searchInMarket; 
      },
      error: (error) => {
        //console.log(error);
        //console.log(error.error.error)
        if (error.error.error === 'There is no sales record') {
          //console.log('no hay datos');
          this.items = [];
          this.loading = false;
        }
      }
    });
  }

    /**
   * This is the function triggered when user click search button
   */
    searchAction = () => {
      //this.loading = true;
      this.keyword = this.searchInput.replace(/ /g, "_");
      //console.log(this.keyword)
      this.currentPage = 1;
      this.searchInMarket();
      //this.getItemList();
    }
  /**
   * This is the function which used minimize and maximize sidebar
   */
  minimizeMenu = () => {
    this.isToggled = this.isToggled ? false : true;
    this.filterActive = !this.filterActive;
    if (this.filterActive === true) { 
     this.filterActiveStyle = "col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12";
    } 
    if (this.filterActive === false) { 
     this.filterActiveStyle = "col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12";
    }
  }

  /**
   * This is the function which used search collection on textbox type
   */
  searchCollectionAction = () => {
    // //console.log("search value ", this.collectionSearchInput)
    if(this.searchRequest) {
      this.searchRequest.unsubscribe();
    }
    this.collectionPage = 1;
    this.searchRequest = this.collectionService.listCollection({
      type:"all",
      keyword: this.collectionSearchInput,
      page: this.collectionPage
    }).subscribe(result=>{
      //console.log(result)
      this.orderId = result;
      //console.log(this.orderId)
      if(result.status == true) {
        this.collections = result.tempArray
      }
    })
  }

  /**
   * This is the function which used to retreive category list from api
   */
  // getCategories = () => {
  //   this.itemService.categoryList().subscribe(result=>{
  //     if(result.status == true) {
  //       this.categories = result.tempArray
  //       if (this.type == "category") {
  //         var index = this.categories.findIndex((category:any) => {
  //             return category._id == this.value
  //         })
  //         if(index != -1) {
  //           // this.page_title = "Search product by category - " + this.categories[index].title
  //           this.pageTitleSubject.next(this.translate.instant("Search product by category") + '-' + this.categories[index].title);
  //         }
  //       }
  //     }
  //   })
  // }

  /**
   * This is the function which used to retreive item list from api
   */
  getItemList = () => {
    this.isApiLoading = true;
    // if(this.includemysteryboxes){
    //   params.includemysteryboxes = true;
    // }
    var params = {}
    if(this.type == "sort") {
      params = {
        page: this.page,
        type: this.value,
        keyword:this.keyword,
        includemysteryboxes: this.includemysteryboxes
      }
    } else if (this.type == "collection") {
      params = {
        page: this.page,
        type: "collection",
        collection_id: this.value,
        includemysteryboxes: true,
        keyword:this.keyword,
      }
    } else if (this.type == "category") {
      params = {
        page: this.page,
        type: "category",
        category_id: this.value,
        keyword:this.keyword,
        includemysteryboxes: this.includemysteryboxes
      }
    } else if (this.type == "price_range") {
      params = {
        page: this.page,
        type: "price_range",
        min: this.min_price,
        max: this.max_price,
        keyword:this.keyword,
        includemysteryboxes: this.includemysteryboxes
      }
    } else if (this.type == "price") {
      params = {
        page: this.page,
        type: "price",
        price_range: this.value,
        keyword:this.keyword,
        includemysteryboxes: this.includemysteryboxes
      }
    }

    this.itemService.listItem(params).subscribe(result=>{
      this.isApiLoading = false;

      let arr = result.tempArray

      const sellMethod1 = arr.map((obj) => {
      return obj.sell_method === 1
    })

    const sellMethod2 = arr.map((obj) => {
      return obj.sell_method === 2
    })

    const tokenID = arr.map((obj) => {
      return obj.token_id !== ''
    })
    //console.log(tokenID)
    if (sellMethod1 || (sellMethod2 && tokenID)) {
      if (result.status == true) {
        if (this.page == 1) {
          this.items = result.tempArray.filter((obj) => obj.hasOwnProperty('orderId') && obj.orderId);
        }
      } else {
        for (let index = 0; index < result.tempArray.length; index++) {
          const element = result.tempArray[index];
          if (element.hasOwnProperty('orderId') && element.orderId) {
            this.items.push(element);
          }
        }
      }


     }
   
      


  
     this.loading = false;
    })
  }


  /**
   * This is the function which used to retreive collection info on sorting
   */
  // getCollectionInfo() {
  //   this.collectionService.viewCollection({
  //     collection_id:this.value
  //   }).subscribe(result => {
  //     this.pageTitleSubject.next(this.translate.instant("Recent product") + '-' + result.result.name);
  //     // this.page_title = "Search product by collection - " + result.result.name
  //   })
  // }


   /**
   * This is the function which used to retreive item list on page scroll
   */
  onNextPage = () => {
    if(this.loading == true && this.isApiLoading == true ) {
      return;
    }
    this.page = this.page + 1;
    this.getItemList();
  }

  /**
   * This is the output function which used to navigate user to item detail page
   */
  itemEvent = (event:any) => {
    if(event.type == "view") {
      this.router.navigate(['/item/view/'+event.item._id])
    }
  }

  /**
   * This is the function triggered when user click reset button
   */
  resetAction = () => {
    this.loading = true;
    this.searchInput = "";
    this.keyword = "";
    this.searchAction();
  }

 


  /**
   * This is the function triggered when user filter the item by category
   */
   categoryAction = (item:any) => {
     var index = this.categories.findIndex((category:any) => {
       return category._id == item._id
     })
     if(index != -1) {
       // //console.log("je")

       // this.page_title = "Search product by category - " + this.categories[index].title
       //this.pageTitleSubject.next(this.translate.instant("Search product by category") + '-' + this.categories[index].title);
     }
   }

}
