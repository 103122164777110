import { Component, Input, OnInit } from '@angular/core';
import { API } from 'src/app/constants/api';

@Component({
  selector: 'app-card-seller',
  templateUrl: './card-seller.component.html',
  styleUrls: ['./card-seller.component.css']
})
export class CardSellerComponent implements OnInit {
  @Input() item: any;
  base_url = API.dgalery_url;

  constructor() { }

  ngOnInit(): void {
  }

  errorImage(event:any) {
    event.target.src = API.url + '/images/profiles/pr-0.png';
  }

}
