<div class="p-5 rounded"
style="background-color: #161616; width: 80vh; filter: drop-shadow(0px 0px 10px #ff004f);">
<div *ngIf="!dataSent">
    <h2 mat-dialog-title class="title" translate>This item includes a physical copy</h2>
    <p translate style="color: white;">Enter your shipping address for this product.</p>

    <div class="contact-form-input">
        <input
          type="text"
          class="form-control rounded border-white form-input  mb-2"
          style="background: transparent; color: white"
          name="name"
          placeholder="{{ 'Name' | translate }}"
          [readonly]="name.length > 0"
          [(ngModel)]="name"
        />
      </div>

    <div class="contact-form-input">
        <input
          type="email"
          class="form-control rounded border-white form-input mb-2"
          style="background: transparent; color: white"
          name="email"
          placeholder="{{ 'Email' | translate }}"
          required
          readonly
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          [(ngModel)]="email"
        />
      </div>

      <div class="contact-form-input">
        <input
          type="text"
          class="form-control rounded border-white form-input  mb-2"
          style="background: transparent; color: white"
          name="phone"
          placeholder="{{ 'Phone' | translate }}"
          required
          [(ngModel)]="phone"
        />
      </div>

      <div class="contact-form-input">
        <input
          type="text"
          class="form-control rounded border-white form-input  mb-2"
          style="background: transparent; color: white"
          name="country"
          placeholder="{{ 'Country' | translate }}"
          required
          [(ngModel)]="country"
        />
      </div>

      <div class="contact-form-input">
        <input
          type="text"
          class="form-control rounded border-white form-input  mb-2"
          style="background: transparent; color: white"
          name="zipCode"
          placeholder="{{ 'Zip code' | translate }}"
          required
          [(ngModel)]="zipCode"
        />
      </div>

      <div class="contact-form-input">
        <input
          type="text"
          class="form-control rounded border-white form-input  mb-2"
          style="background: transparent; color: white"
          name="province"
          placeholder="{{ 'State' | translate }}"
          required
          [(ngModel)]="province"
        />
      </div>

      <div class="contact-form-input">
        <input
          type="text"
          class="form-control rounded border-white form-input  mb-2"
          style="background: transparent; color: white"
          name="address"
          placeholder="{{ 'Address' | translate }}"
          required
          [(ngModel)]="address"
        />
      </div>

      <div class="d-flex justify-content-center align-items-center py-2 mt-2">
    <button style="    
    background: #FF004F;
    border: none;
    font-weight: bold;
    padding: 8px 29px;
    color: white!important;"
    (click)="sendDeliveryInfo()"
    translate
    >Send delivery info</button>
    </div>
  </div>

  <div *ngIf="dataSent">
    <h2 mat-dialog-title class="title" translate>Data sent successfully</h2>
    <p translate style="color: white;">
      Your delivery information has been sent successfully. You will receive a confirmation email.
    </p>

    <div class="d-flex justify-content-center align-items-center py-2 mt-2">
      <button style="    
      background: #FF004F;
      border: none;
      font-weight: bold;
      padding: 8px 29px;
      color: white!important;"
      (click)="closeDialog()"
      translate
      >Close</button>
  </div>

  
</div>

<app-loader *ngIf="spinner" [loadingText]="'Sending shipping data...' | translate"></app-loader>