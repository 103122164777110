import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CountdownConfig } from 'ngx-countdown';
import { API } from 'src/app/constants/api';
import { ItemService } from 'src/app/services/item.service';

const CountdownTimeUnits: Array<[string, number]> = [
  ['Y', 1000 * 60 * 60 * 24 * 365], // years
  ['M', 1000 * 60 * 60 * 24 * 30], // months
  ['D', 1000 * 60 * 60 * 24], // days
  ['H', 1000 * 60 * 60], // hours
  ['m', 1000 * 60], // minutes
  ['s', 1000], // seconds
  ['S', 1], // million seconds
];
@Component({
  selector: 'app-card2',
  templateUrl: './card2.component.html',
  styleUrls: ['./card2.component.css']
})
export class Card2Component implements OnInit {
  @Input('img') img = '';
  @Input() item: any;
  now: number;
  cdConfig: CountdownConfig;
  
  baseUrl = API.url;

  constructor(private itemService: ItemService, private router: Router) {
    this.now = moment().unix();
    this.cdConfig = {
      leftTime: 0
    };
   }

  ngOnInit(): void {
    this.cdConfig = {
      leftTime: this.timeLeft(),
      formatDate: ({ date, formatStr }) => {
        let duration = Number(date || 0);

        return CountdownTimeUnits.reduce((current, [name, unit]) => {
          if (current.indexOf(name) !== -1) {
            const v = Math.floor(duration / unit);
            duration -= v * unit;
            return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
              return v.toString().padStart(match.length, '0');
            });
          }
          return current;
        }, formatStr);
      },
      
    };
  }

  verAdminSales(){
    this.itemService.getAdminMarketplace().subscribe({
      next: (result: any) => {
        //console.log(result);
      },
      error: (error: any) => {
        //console.log(error);
      }
    })
  };

  verDetalle(id: any){
    //console.log(id);
    this.router.navigate(['/item/view', id])
  }
 
  timeLeft = () => {
    if (this.item?.sell_method === 2) {
      if (this.now < this.item?.expire_at) {
        return this.item?.expire_at - this.now + 133;
      }
    }
    return 0;
  }

}
