import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NodataComponent } from "./nodata.component";
import {MatIconModule} from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        TranslateModule
    ],
    exports: [NodataComponent],
    declarations: [NodataComponent],
    providers: [],
})
export class NodataModule {
}
