<div class="p-5 rounded"
style="background-color: #161616; max-width: 800px; filter: drop-shadow(0px 0px 10px #ff004f);">
    <h2 mat-dialog-title class="title" translate>Place a bid to acquire this unique art</h2>
    <p translate>Place your bid for a chance to own this piece of digital art uniqueness. Ensure you're aware of the auction's details and terms. Good luck!</p>
    <span class="input-group-text" 
      style="background: transparent; color: white; border: none; 
      padding: 0px 0px 0px 10px; margin: 0px 0px 0px 0px;" 
      translate>{{'Current offer:' | translate}} {{data.price}} USDT</span>
    
    <div class="contact-form-input">
      
      <div class="d-flex mt-4" style="gap: 10px;">
        <span class="input-group-text" 
        style="background: transparent; color: white; border: none; 
        padding: 0px 0px 0px 10px; margin: 0px 0px 0px 0px;" 
        translate>Your offer: </span>
        <div>
          <input
          type="number"
          class="form-control rounded border-white form-input mb-2"
          style="background: transparent; color: white; width: 150px; height: 100%;"
          name="price"
          placeholder="Enter new price"
          required
          [(ngModel)]="price"
          (change)="handleInputChange()"
        />
        </div>
        
        <img src="../../../../assets/images/tether.svg" style="max-width: 35px;" alt="USDT" />


      </div>
        
      


      <div class="d-flex justify-content-center align-items-center py-2 mt-4">
    <button style="    
    background: #FF004F;
    border: none;
    font-weight: bold;
    padding: 8px 29px;
    color: white!important;"
    (click)="checkBalance()"
    translate
    >Place a bid</button>
    </div>

</div>

<app-loader *ngIf="spinner"></app-loader>
