<section id="subheader" class="text-light" style="background: url('../../../assets/images/backgroundhome.png') center bottom / cover;">
  <div class="center-y relative text-center">
    <div class="container">
      <div class="row">

        <div class="col-md-12 text-center">
          <h1 class="fs-explore" translate>Activity</h1>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</section>
<!-- section close -->
<div class="collectionMainContainer">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="tradeBlock">
          <div class="animated fadeIn">
            <div class="card">
              <div class="card-header" style="color: black!important; background-color: #f3f3f3;">
                <i class="cil-swap-vertical titleIcon"></i>
                {{'Activity'|translate}}
              </div>
              <div class="card-body historyBody">
                <table class="table  table-bordered table-striped table-sm">
                  <thead>
                    <tr style="background-color: #f3f3f3 !important;">
                      <!-- <th translate>Collection</th> -->
                      <th translate>Item</th>
                      <th translate>Event</th>
                      <th translate>Price</th>
                      <th translate>From</th>
                      <th translate>To</th>
                      <th translate>Transaction Hash</th>
                      <th translate>Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of histories" style="background-color: #f3f3f3 !important;">
                      <!-- <td>
                        <div *ngIf="item.collection_id != null">
                          <div class="itemDetail" [routerLink]="'/collection/detail/' + item.collection_id._id"><img
                              [src]="mediaBase + '/images/collection/logo/' + item.collection_id.image"></div>
                        </div>
                      </td> -->

                      <!-- tumb -->
                      <td>
                        <!-- <div class="itemDetail" [routerLink]="'/item/view/' + item._id"><img
                            [src]="'https://apimpdgalery.devtop.online/api/v1/images/defaults/nouser.png'"></div> -->
                            <div class="itemDetail" *ngIf="!item.transfer_type" [routerLink]="'/item/view/' + item.tokenId">
                              <img [src]="baseUrl + item.tokensData.url_thumbs"></div>
                              <div class="itemDetail" *ngIf="item.transfer_type" [routerLink]="'/item/view/' + item.tokenId">
                                <img [src]="baseUrl + item.url_thumbs"></div>
                      </td>

                      <!-- event -->
                      <div translate *ngIf="item.transfer_type">{{item.transfer_type }}</div>
                      <div translate *ngIf="item.status == 'onSale'">{{item.status}}</div>
                      <div translate *ngIf="item.status == 'purchased'">Sale</div>
                      <!-- event -->

                      <td>{{item.price ? item.price + ' USDT' : 'N/A'}}</td>

                      <td>
                        <div *ngIf="item.seller && !item.bidder"><a href="javascript:void(0)"
                            [routerLink]="'/profile/'">
                            {{item.seller.wallet.substr(0,4)}}...{{item.seller.wallet.substr(-4)}}</a></div>
                            <div *ngIf="!item.seller && !item.bidder"><a href="javascript:void(0)"
                              [routerLink]="'/profile/'">
                             DGalery</a></div>
                             <div *ngIf="item.bidder"><a href="javascript:void(0)"
                              [routerLink]="'/profile/'">
                              {{item.bidder.substr(0,4)}}...{{item.bidder.substr(-4)}}</a></div>
                            
                      </td>

                      <td>
                        <div *ngIf="item.buyer"><a href="javascript:void(0)"
                            [routerLink]="'/collection/profile/' + item.buyer.id">
                            {{item.buyer.wallet.substr(0,4)}}...{{item.buyer.wallet.substr(-4)}}</a></div>
                            <div *ngIf="item.status == 'onSale'"><a href="javascript:void(0)"
                              [routerLink]="'/marketplace'">
                             Marketplace</a></div>
                             <div *ngIf="item.owner"><a href="javascript:void(0)"
                              [routerLink]="'/marketplace'">
                              {{item.owner.substr(0,4)}}...{{item.owner.substr(-4)}}</a></div>
                              <div *ngIf="item.bidder"><a href="javascript:void(0)"
                                [routerLink]="'/collection/profile/' + item.idBidder">
                                {{item.bidder.substr(0,4)}}...{{item.bidder.substr(-4)}}</a></div>
                      </td>
                      <td>
                        <div *ngIf="item.transactionHash != ''"><a
                            [href]="blockChainExplorer + 'tx/'+item.transactionHash"
                            target="_blank">{{item.transactionHash}}</a>
<!--                          <span *ngIf="item?.data?.gasUsed">-->
<!--                            <br />Gas: {{ item.data.gasUsed}}-->
<!--                          </span>-->
                        </div>
                      </td>
                      <td>{{item.createdAt | amDateFormat:'YYYY-MM-DD HH:mm:ss'}}</td>
                    </tr>
                    <tr *ngIf="histories.length==0 && historyLoading == false">
                      <td colspan="8">
                        <alert type="info" ng-reflect-type="info">
                          <div role="alert" class="alert alert-info" ng-reflect-ng-class="">
                            <strong>Oops!</strong> {{'No Data Found'|translate}}
                          </div>
                        </alert>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <nav class="mt-2">
                  <ul class="pagination" *ngIf="totalPages > 1 && !historyLoading">
                    <li class="page-item" *ngIf="currentPage > 1"><a class="page-link" href="javascript:void(0)"
                        (click)="prevHistoryPage()" translate>Prev</a></li>
                    <li class="page-item" *ngIf="currentPage < totalPages"><a class="page-link" href="javascript:void(0)"
                        (click)="nextHistoryPage()" translate>Next</a></li>
                  </ul>
                  <ul class="pagination" *ngIf="historyLoading">
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" translate>Loading...</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Join to the community -->
  <section style="background-color: #000000" *ngIf="!currentUser">
    <div class="container" style="margin-bottom: -1rem">
      <div class="text-center">
        <h1 translate style="color: #ff004f">Join to the community</h1>
      </div>
      <div>
        <p style="color: white !important" class="text-center" translate>
          Join the creative revolution
      </div>
      <!-- <button (click)="obtenerHistorial()">aaa</button> -->
      <div class="text-center">

        <button
          style="
            background: #ff004f;
            border: none;
            font-weight: bold;
            padding: 8px 29px;
            color: white !important;
            margin-top: 1rem;
          "
        >
          <span translate>Connect Wallet</span>
        </button>
      </div>
      <div
        class="d-flex align-items-center justify-content-center mt-5 social__media__icons"
        style="color: #fff; justify-content: space-between"
      >
        <img
          src="../../../assets/social-media-icons/twitter-x.svg"
          class="mx-auto"
        />
        <img
          src="../../../assets/social-media-icons/instagram.svg"
          class="mx-auto"
        />
        <img
          src="../../../assets/social-media-icons/discord.svg"
          class="mx-auto"
        />
        <img
          src="../../../assets/social-media-icons/reddit.svg"
          class="mx-auto"
        />
        <img
          src="../../../assets/social-media-icons/youtube.svg"
          class="mx-auto"
        />
        <img
          src="../../../assets/social-media-icons/tiktok.svg"
          class="mx-auto"
        />
        <img
          src="../../../assets/social-media-icons/email.svg"
          class="mx-auto"
        />
      </div>
    </div>
  </section>
</div>
