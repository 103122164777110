<div class="p-5 rounded" 
style="background-color: #161616; max-width: 100vh; filter: drop-shadow(0px 0px 10px #ff004f);">
    <h2 mat-dialog-title class="title" translate>Legal warning</h2>
<mat-dialog-content class="mat-typography">
  <p class="p" translate>Legal Warning 1</p>
  <p class="p" translate>Legal Warning 2</p>
  <p class="p" translate>Legal Warning 3</p>
  <p class="p" translate>Legal Warning 4</p>
  <p class="p" translate>Legal Warning 5</p>
  <p class="p" translate>Legal Warning 6</p>
  <p class="p" translate>Legal Warning 7</p>
  <p class="p" translate>Legal Warning 8</p>
  <p class="p" translate>Legal Warning 9</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button style="    
    background: #FF004F;
    border: none;
    font-weight: bold;
    padding: 8px 29px;
    color: white!important;
    margin-top: 1rem;" 
    translate
    mat-button [mat-dialog-close]="false">Agree</button>
</mat-dialog-actions>
</div>