
    <div class="nft__item1" *ngIf="oldView">
        <!-- <div class="de_countdown bg-color-secondary text-white" data-year="2021" data-month="8" data-day="16" data-hour="8">
        </div> -->
        <div  style="font-weight: bold;      
        border-radius: 20px;
        top: -1px;
        right: 0px;" *ngIf="item.sell_method == 2 && timeLeft() > 0 && item.for_sale" class="de_countdown bg-color-secondary text-white is-countdown">
            <countdown [config]="cdConfig"></countdown>
        </div>
        <div class="nft__item_wrap1">
            <a href="javascript:void(0)" (click)="viewAction()">
                <img *ngIf="item.token_id != null && !isVideo" [src]="mediaBase + '/images/item/thumb/' + item.thumb" class="lazy nft__item_preview">
                <video #video *ngIf="item.token_id != null && isVideo" width="100%" style="max-height:300px; padding: 10px;" 
                (loadedmetadata)="video.muted = true"
                (loadedmetadata)="video.controls = true">
                    <source [src]="mediaBase + '/images/item/thumb/' + item.thumb" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
                <img *ngIf="item.token_id == null" [src]="mediaBase+'/images/item/thumb/'+ item.thumb" style="padding: 10px;">
            </a>
        </div>
        <div class="nft__item_info1">
            <a href="javascript:void(0)" (click)="viewAction()">
                <h3 style="color: #fff;" >{{item.name}}</h3>
            </a>
  
            <div class="nft__item_like">
          
                <img src="../../../assets/images/etiqueta.png" alt="">

                <!-- <div class="moreAction dropdown dropright" dropdown *ngIf="item.status == 'inactive' && isOwner() && displayActions">
                    <button [id]="'morelink-dropdown'" class="btn" dropdownToggle aria-controls="more-dropdown"><i
                            class="icon-options-vertical" style="    color: white;"></i></button>
                    <div [id]="'more-dropdown'" style="    border: none;
                    border-radius: 0px;
                    color: white!important;
                    background-color: #111432e4;"  class="dropdown-menu dropdown-menu-right" aria-labelledby="morelink-dropdown"
                         *dropdownMenu >
                        <a  style="     color: white!important;" class="dropdown-item" href="javascript:void(0)" (click)="editAction()" translate>Edit</a>
                        <a  style="     color: white!important;"  class="dropdown-item" href="javascript:void(0)" (click)="deleteAction()" translate>Delete</a>
                    </div>
                </div> -->
            </div>
            <h4 style="color: white" class="d-inline-block mr-1">{{item.price}} USDT</h4>
            <div class="text-center">
                <button style="background: #FF004F;
                border: none;
                font-weight: bold;
                color: white!important;
                width: 100%;
                margin-top: 1rem;"  (click)="viewAction()">
                    <span translate>
                        VER MAS
                    </span>
                </button>
            </div>
            <div class="text-center" *ngIf="isOwner() && isOrderExist()">
                <button class="hide-mobil"   
                style="background: #FF004F;
                border: none;
                font-weight: bold;
                color: white!important;
                width: 100%;
                margin-top: 1rem;" 
                href="javascript:void(0)" 
                (click)="publishAction()" translate>Publish</button>
            </div>
            
        </div>
    </div>

    <div class="nft__item1">
            
          <div class="image-container">
            <img [src]="baseUrl + '/images/collections/' + item.url">
            <!--eerer-->
          </div>

            <div class="tag"
            *ngIf="item.onSale">
                <img  style="position: relative; width: 45px; top: 3px; left: 3px;"
                src="../../../assets/images/sale-white.svg" alt="onSale"
                data-toggle="tooltip" title="{{'This NFT is on sale' | translate}}" class="d-inline-block">
            </div>

            <div class="tag d-flex justify-content-center align-items-center" style="transform: rotate(0deg);"
            *ngIf="item.itemShipping && item.role == 1002" data-toggle="tooltip"
            title="{{'This item has pending shipping information' | translate}}">
                <i class="fa fa-truck fa-2x" aria-hidden="true"></i>
            </div>
            
                <button class="hide-mobil btn-item"  
                href="javascript:void(0)" 
                *ngIf="item.tokenId == undefined && item.isDownload"
                (click)="publish(item, user)"
                 translate>{{item.isIpfs ? 'Continue to mint' : 'Publish'}}</button>
                 <button class="hide-mobil btn-item"  
                href="javascript:void(0)" 
                *ngIf="!item.isDownload"
                (click)="downloadItem()"
                 translate>Try to import again</button>

                 <button class="hide-mobil btn-item"
                 href="javascript:void(0)" 
                 *ngIf="item.tokenId !== undefined"
                 (click)="collectedItem()"
                  translate>View NFT</button> <!-- {{item.onSale ? 'View NFT' : 'Sell'}} -->
    </div>

    <div class="nft__item1" *ngIf="item.sell_method  && pathName != 'profile'">
        
        <div class="nft__item_wrap1">
            <a href="javascript:void(0)">
                <img [src]="baseUrl + '/images/collections/' + item.url" class="lazy " alt="">

            </a>
        </div>

        <div class="nft__item_info1">
            <a href="javascript:void(0)" >
                <!-- <h4 class="mb-0">{{item.tokenData.title}}</h4> -->
                <h4 class="mb-0">{{item.title}}</h4>
                <h4 class="mb-0" *ngIf="!item.title"> generic title</h4>
            </a>
            <div class="nft__item_like" style=" padding-top: 10px;">
                <img src="../../../assets/images/etiqueta.png" alt="">
            </div>
            <h5 style="color: white!important; padding-top: 10px;">{{item.price}} USDT</h5>
          <div class="text-center">
                <button style="background: #FF004F;
                border: none;
                font-weight: bold;
                color: white!important;
                width: 100%;
                margin-top: 1rem;"
                (click)="viewItem(item.orderId)">
                    <span translate>
                        VER MAS
                    </span>
                </button>
            </div>
        </div>
    

    
        </div>



    <app-loader *ngIf="spinner"></app-loader>