
<div class="nft__item1">
    <!-- <div class="de_countdown bg-color-secondary text-white" data-year="2021" data-month="8" data-day="16" data-hour="8">
    </div> -->
    <div class="nft__item_wrap1">
        <a href="javascript:void(0)" (click)="viewAction()">
            <img *ngIf="item.token_id != null && !isVideo" [src]="mediaBase + '/images/item/thumb/' + item.thumb" class="lazy nft__item_preview">
            <video #video *ngIf="item.token_id != null && isVideo" width="100%" style="max-height:300px; padding: 10px;" 
            (loadedmetadata)="video.muted = true"
            (loadedmetadata)="video.controls = true">
                <source [src]="mediaBase + '/images/item/thumb/' + item.thumb" type="video/mp4">
                Your browser does not support the video tag.
            </video>
            <img *ngIf="item.token_id == null" [src]="mediaBase+'/images/item/thumb/'+ item.thumb" style="padding: 10px;">
        </a>
    </div>
    <div class="nft__item_info1">
        <a href="javascript:void(0)" (click)="viewAction()">
            <h3 style="color: #fff;" >{{item.name}}</h3>
        </a>

        <div class="nft__item_like">
      
            <img src="../../../assets/images/etiqueta.png" alt="">

            <div class="moreAction dropdown dropright" dropdown *ngIf="item.status == 'inactive' && isOwner() && displayActions">
                <button [id]="'morelink-dropdown'" class="btn" dropdownToggle aria-controls="more-dropdown"><i
                        class="icon-options-vertical" style="    color: white;"></i></button>
                <div [id]="'more-dropdown'" style="    border: none;
                border-radius: 0px;
                color: white!important;
                background-color: #111432e4;"  class="dropdown-menu dropdown-menu-right" aria-labelledby="morelink-dropdown"
                     *dropdownMenu >
                    <a  style="     color: white!important;" class="dropdown-item" href="javascript:void(0)" (click)="editAction()" translate>Edit</a>
                    <a  style="     color: white!important;"  class="dropdown-item" href="javascript:void(0)" (click)="deleteAction()" translate>Delete</a>
                   <!-- <a  style="     color: white!important;"  class="dropdown-item" href="javascript:void(0)" (click)="publishAction()" translate>Publish</a> -->
                </div>
            </div>
        </div>
        <h4 style="color: white" class="d-inline-block mr-1">{{item.price}} USDT</h4>
        <div class="text-center">
            <button style="background: #FF004F;
            border: none;
            font-weight: bold;
            color: white!important;
            width: 100%;
            margin-top: 1rem;"  (click)="viewAction()">
                <span translate>
                    VER MAS
                </span>
            </button>
        </div>
    </div>
</div>

<div  style="font-weight: bold; border: 1px solid white; margin-bottom: 2rem; color: white; font-size: 18px;
padding: 5px;" *ngIf="item.sell_method == 2 && timeLeft() > 0 && item.for_sale" class="text-center">
    <countdown [config]="cdConfig"></countdown>
</div>