import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API } from "../constants/api";
import { Observable } from "rxjs";
import { lang } from "moment";

@Injectable({
  providedIn: "root",
})
export class EmailService {
  constructor(private http: HttpClient) {}

  sendEmail(
    name: string,
    email: string,
    message: string,
    captcha: string,
    language: string
  ): Observable<any> {
    let url = API.url + API.send_data;
    const data = {
      names: name,
      email: email,
      message: message,
      captcha: captcha,
      language: language
    };
    return this.http.post<any>(url, data);
  }
}
