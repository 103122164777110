
            <div class="nft__item1">
                <!-- <div class="de_countdown bg-color-secondary text-white" data-year="2021" data-month="8" data-day="16" data-hour="8">
                </div> -->

                <!-- <div  style="font-weight: bold; top: 0px; right: 0px; border-radius: 0px; border-bottom-left-radius: 30px;" 
                *ngIf="item.sell_method == 2 && timeLeft() > 0" class="de_countdown bg-color-secondary text-white is-countdown">
                    <countdown [config]="cdConfig"></countdown>
                </div> -->
 


                <div class="nft__item_wrap1">
                    <a href="javascript:void(0)">
                        <img [src]="baseUrl + '/images/collections/' + item.url" class="lazy " alt="">
                    </a>
                </div>
                <div class="nft__item_info1">
                    <a href="javascript:void(0)">
                        <div class="d-flex">
                            <h4 class="mb-0">{{item.title}}</h4>
                            <img src="../../../assets/images/verify.svg" alt="verify" style="width: 28px; margin-left: -1px;">
                        </div>
                    </a>
                    <div class="nft__item_like" style=" padding-top: 8px;">
                        <img style="width: 48px;" src="../../../assets/images/tags/delivery-tag.webp" alt="">

                    </div>
                    <div class="nft__item_like" style=" padding-top: 8px;">
                        <img style="width: 50px;" src="../../../assets/images/tags/admin-tag.webp" alt="">
                    </div>
                    <h5 style="color: white!important; padding-top: 10px;">{{item.price}} USDT</h5>
                  <div class="text-center">
                        <button style="background: #C5A500;
                        border: none;
                        font-weight: bold;
                        color: white!important;
                        width: 100%;
                        margin-top: 1rem;"
                        (click)="verDetalle(item.tokenId)">
                            <span translate>
                                VER MAS
                            </span>
                        </button>
                    </div>
                </div>
        

        <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div class="nft__item1">
                -- <div class="de_countdown bg-color-secondary text-white" data-year="2021" data-month="8" data-day="16" data-hour="8">
                </div> --

                <div class="nft__item_wrap1">
                    <a href="javascript:void(0)">
                        <img [src]="'../../../assets/images/image2.png'" class="lazy " alt="">
                    </a>
                </div>
                <div class="nft__item_info1">
                    <a href="javascript:void(0)">
                        <div class="d-flex">
                            <h4 class="mb-0">LOREM IPSU</h4>
                            <img src="../../../assets/images/verify.svg" alt="verify" style="width: 28px; margin-left: -1px;">
                        </div>
                    </a>
                    <div class="nft__item_like" style=" padding-top: 10px;">
                        <img style="width: 120%;" src="../../../assets/images/etiqueta2.png" alt="">
                    </div>
                    <div class="nft__item_like" style=" padding-top: 10px;">
                        <img src="../../../assets/images/etiqueta.png" alt="">
                    </div>

                    <h5 style="color: white!important; padding-top: 10px;">50 USDT</h5>
                    <div class="text-center">
                        <button style="background: #C5A500;
                        border: none;
                        font-weight: bold;
                        color: white!important;
                        width: 100%;
                        margin-top: 1rem;">
                            <span translate>
                                VER MAS
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div class="nft__item1">
                -- <div class="de_countdown bg-color-secondary text-white" data-year="2021" data-month="8" data-day="16" data-hour="8">
                </div> --

                <div class="nft__item_wrap1">
                    <a href="javascript:void(0)" >
                        <img   [src]="'../../../assets/images/image3.png'" class="lazy " alt="">
                    </a>
                </div>
                <div class="nft__item_info1">
                    <a href="javascript:void(0)">
                        <div class="d-flex">
                            <h4 class="mb-0">LOREM IPSU</h4>
                            <img src="../../../assets/images/verify.svg" alt="verify" style="width: 28px; margin-left: -1px;">
                        </div>
                        
                    </a>
                    <div class="nft__item_like" style=" padding-top: 10px;">
                        <img style="width: 120%;" src="../../../assets/images/etiqueta2.png" alt="">
                    </div>
                    <div class="nft__item_like" style=" padding-top: 10px;">
                        <img src="../../../assets/images/etiqueta.png" alt="">
                    </div>
                    <h5 style="color: white!important; padding-top: 10px;">50 USDT</h5>
                    <div class="text-center">
                        <button style="background: #C5A500;
                        border: none;
                        font-weight: bold;
                        color: white!important;
                        width: 100%;
                        margin-top: 1rem;">
                            <span translate>
                                VER MAS
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div class="nft__item1">
                -- <div class="de_countdown bg-color-secondary text-white" data-year="2021" data-month="8" data-day="16" data-hour="8">
                </div> --

                <div class="nft__item_wrap1">
                    <a href="javascript:void(0)">
                        <img [src]="'../../../assets/images/image4.png'" class="lazy " alt="">
                    </a>
                </div>
                <div class="nft__item_info1">
                    <a href="javascript:void(0)">
                        <div class="d-flex">
                            <h4 class="mb-0">LOREM IPSU</h4>
                            <img src="../../../assets/images/verify.svg" alt="verify" style="width: 28px; margin-left: -1px;">
                        </div>
                    </a>
            
                    <div class="nft__item_like" style=" padding-top: 10px;">
                        <img style="width: 120%;" src="../../../assets/images/etiqueta2.png" alt="">
                    </div>
                    <div class="nft__item_like" style=" padding-top: 10px;">
                        <img src="../../../assets/images/etiqueta.png" alt="">
                    </div>
                    <h5 style="color: white!important; padding-top: 10px;">50 USDT</h5>
                    <div class="text-center">
                        <button style="background: #C5A500;
                        border: none;
                        font-weight: bold;
                        color: white!important;
                        width: 100%;
                        margin-top: 1rem;">
                            <span translate>
                                VER MAS
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div> -->
