export const API = {
  base_url: 'https://antiguaapi.devtop.onlines/api/v1',
  //url: 'http://192.168.1.108:3050/api/v1',
  url: 'https://apimarketplace.dgalery.com/api/v1',
  dgalery_api: 'https://api.dgalery.com/api/v1',
  dgalery_url: 'https://api.dgalery.com',
  settings_action: "/settings",
  login_wallet: "/signin/",
  user_login: "/user/login",
  user_login_wallet: "/user/loginWallet",
  user_auth: "/user/auth",
  user_register: "/user",
  user_best_sellers: "/user/best-sellers",
  user_forgot: "/user/forgot",
  user_reset: "/user/reset",
  user_profile: "/user/profile",
  user_update: "/user/update",
  user_update_metamask: "/user/updateaddress",
  item_check_balance: "/item/checkbalance",
  item_transfer_balance: "/item/sendeth",
  collection_add: "/collection/add",
  collection_update: "/collection/update",
  collection_sync: "/collection/sync",
  collection_detail: "/collection/detail",
  fixed_attributes: "/collection/fixed_attributes",
  itemsByFixedAttributes: "/collection/items_by_fixed_attributes",
  percentagesOfFixedAttributes: "/collection/percentages_of_fixed_attributes",
  openMysteryBox: "/collection/open_mystery_box",
  collection_smart_contract: "/collection/sc",
  collection_delete: "/collection/delete",
  collection_list: "/collection/list",
  category_list: "/category/list",
  category_list_student: "/categoryoptionsestudiante/list",
  category_list_sportsman: "/categoryoptionsdeportista/list",
  item_add: "/item/add",
  item_update: "/item/update",
  item_delete: "/item/delete",
  item_list: "/item/list",
  item_favourite_list: "/item/favouriteslist",
  item_listbycollection: "/item/listbycollection",
  item_publish: "/item/publish",
  item_sync_mint: "/item/syncMint",
  item_sync_sale_status: "/item/syncMetadata",
  items_sync_mint: "/item/syncMints",
  item_sync_purchase: "/item/syncPurchase",
  item_sync_auction: "/item/syncAuction",
  item_purchase: "/item/purchase",
  item_views_add: "/item/addviews",
  item_like_add: "/item/addfavourites",
  item_report: "/item/report",
  item_more_collection: "/item/morefromcollection",
  item_history: "/item/history",
  item_prices: "/item/prices",
  convert_price: "/item/convert_price",
  item_data_deploy: "/item/datatodeploy",
  market_data_deploy: "/item/datatodeploymarket",
  item_offers: "/item/offers",
  item_offer_add: "/item/addoffer",
  item_update_price: "/item/updateprice",
  item_toggle_forsale: "/item/toggleforsale",
  item_offer_remove: "/item/removeoffer",
  item_offer_action: "/item/actionoffer",
  get_recent_posts: "https://news.marketis.io/wp-json/wp/v2/posts?per_page=3",
  get_tokens_list: "/settoken/list",
  get_tokens_accepted: "/settoken/detail",
  stripe_buy: "/stripe/charge",
  send_data: "/send",
};
