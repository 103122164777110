import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { OnboardService } from 'src/app/services/onboard.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-change-order',
  templateUrl: './dialog-change-order.component.html',
  styleUrls: ['./dialog-change-order.component.css']
})
export class DialogChangeOrderComponent implements OnInit {

  auctionTime: any;
  expirationDate: any;
  expirationAuction: any;
  timeForAuction = [ {
    days: '1 day',
    value: 1
  },
  {
    days: '3 days',
    value: 3
  },
  {
    days: '5 days',
    value: 5
  },
  {
    days: '7 days',
    value: 7
}]

  price: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private onboardService: OnboardService,
    private snackBar: MatSnackBar,
    private dialogService: DialogService,
    private loaderService: LoaderService,
    public dialogRef: MatDialogRef<DialogChangeOrderComponent>
    ) { }

  ngOnInit(): void {
  }

  calculateExpiryDate() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + Number(this.auctionTime));
    this.expirationAuction = currentDate;
    this.expirationDate = Math.ceil(this.expirationAuction.getTime() / 1000);
  }
  
  getDateFormat() {
    const lang = this.translateService.currentLang;
    return lang === 'es' ? 'dd/MM/yyyy HH:mm' : 'MM/dd/yyyy HH:mm';
  }

  changeOrder() {

    if (this.price <= 0) {
      this.snackBar.open(this.translateService.instant('Please enter a valid price'), '', {});
      return;
    }

    if ((this.data.sellMethod == 1 )&& (this.price == this.data.price)) {
      this.snackBar.open(this.translateService.instant('Please enter a different price'), '', {});
      return;
    }
    
    if ((this.data.sellMethod == 2) && !this.expirationDate) {
      this.snackBar.open(this.translateService.instant('Please select the auction time'), '', {});
      return;
    }

    const data = {
      orderId: this.data.orderId,
      tokenPrice: this.price,
      tokenExpireAt: this.data.sellMethod == 2 ? this.expirationDate : 0
    };

    this.loaderService.show();
    this.onboardService.changeOrder(data).then((res: any) => {
      this.loaderService.hide();
      this.dialogService.openDialogCustom(
        {
          title: 'Order changed', 
          content: 'Your order has been changed successfully.',
          btnText: 'Reload',
          callback: () => this.reload()
        }
      )
    })
    .catch((err: any) => {
    });
  }

  reload() {
    window.location.reload();
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
