<div class="p-5 rounded"
style="background-color: #161616; width: 80vh; filter: drop-shadow(0px 0px 10px #ff004f);">
    <h2 mat-dialog-title class="title" translate>Welcome admin</h2>
    <p translate>You are admin, but you haven't linked your account yet. Log in with your DGalery account.</p>
    <div class="contact-form-input">
        <input
          type="email"
          class="form-control rounded border-white form-input mb-2"
          style="background: transparent; color: white"
          name="email"
          placeholder="Email"
          required
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          [(ngModel)]="email"
        />
      </div>
    
      <div class="contact-form-input">
        <input
          type="password"
          class="form-control rounded border-white form-input"
          style="background: transparent; color: white"
          name="password"
          placeholder="password"
          required
          [(ngModel)]="password"
        />
      </div>

      <div class="d-flex justify-content-center align-items-center py-2 mt-2">
    <button style="    
    background: #FF004F;
    border: none;
    font-weight: bold;
    padding: 8px 29px;
    color: white!important;"
    (click)="loginAdminDGalery()"
    translate
    >Link</button>
    </div>

</div>
