import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from "rxjs";
import { map } from 'rxjs/operators';
import { Common } from '../models/common';
import { API } from '../constants/api'
import { environment } from 'src/environments/environment';

const url = environment.url;
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private setting: BehaviorSubject<any>;
  public currentSetting: Observable<any>;

  // private crypto : BehaviorSubject<string>;
  // public currentCrypto : Observable<string>;

  // private ethNetwork : BehaviorSubject<string>;
  // public currentEthNetwork : Observable<string>;

  // private adminCommission : BehaviorSubject<number>;
  // public currentadminCommission : Observable<number>;

  // private contractAddress : BehaviorSubject<string>;
  // public currentContractAddress : Observable<string>;

  // private contractAbi : BehaviorSubject<string>;
  // public currentContractAbi : Observable<string>;

  // private contractByteCode : BehaviorSubject<string>;
  // public currentContractByteCode : Observable<string>;


  constructor(
    private http: HttpClient
  ) {
    // this.crypto = new BehaviorSubject(this.getCurrency());
    // this.currentCrypto = this.crypto.asObservable();
    // this.ethNetwork = new BehaviorSubject(this.getEthNetwork());
    // this.currentEthNetwork = this.ethNetwork.asObservable();
    // this.adminCommission = new BehaviorSubject(this.getCommission());
    // this.currentadminCommission = this.adminCommission.asObservable();

    // this.contractAddress = new BehaviorSubject(this.getCommission());
    // this.currentadminCommission = this.adminCommission.asObservable();

    this.setting = new BehaviorSubject(this.getSetting());
    this.currentSetting = this.setting.asObservable();
  }

  // updateCurrency(currency: string) {
  //   this.crypto.next(currency)
  //   localStorage.setItem('currency', currency);
  // }

  // updateEthNetwork(eth_network: string) {
  //   this.crypto.next(eth_network)
  //   localStorage.setItem('eth_network', eth_network);
  // }

  // updateCommission(admin_commission: string) {
  //   this.adminCommission.next(admin_commission)
  //   localStorage.setItem('admin_commission', `${admin_commission}`);
  // }

  // updateContractAddress(contract_address: number) {
  //  this.contractAddress.next(contract_address)
  //   localStorage.setItem('contract_address', `${contract_address}`);
  // }

  updateSetting(setting: any) {
    this.setting.next(setting)
    localStorage.setItem('setting', JSON.stringify(setting));
  }

  getSetting = () => {
    var setting = localStorage.getItem('setting');
    return setting != null ? JSON.parse(setting) : {};
  }

  /**
   * This is the function which used to get the currency
   */
  // getCurrency = () => {
  //   var currency = localStorage.getItem('currency');
  //   return currency == null ? '' : currency;
  // }

  // getEthNetwork = () => {
  //   var eth_network = localStorage.getItem('eth_network');
  //   return eth_network == null ? '' : eth_network;
  // }

  // getCommission = () => {
  //   var admin_commission = localStorage.getItem('admin_commission');
  //   return admin_commission == null ? 0 : parseFloat(admin_commission);
  // }

  // getContractAddress = () => {
  //   var contract_address = localStorage.getItem('contract_address');
  //   return contract_address ?? "";
  // }

  loadSettings(): Observable<Common> {
    let url = API.base_url + API.settings_action;
    return this.http.get<Common>(url).pipe(map((param: any) => new Common().deserialize(param)));
  }


  // API WORDPRESS NEWSS
  getRecentPosts(): Observable<Common> {
    const url = API.get_recent_posts;
    return this.http.get<Common>(url).pipe(map((param: any) => new Common().deserialize(param)));
  }

}
