<div class="p-5 rounded" *ngIf="!register"
style="background-color: #161616; width: 80vh; filter: drop-shadow(0px 0px 10px #ff004f);">
    <h2 mat-dialog-title class="title" translate>Requiere Autenticacion</h2>
    <p translate>Your wallet is not in our database. If you already have a dgalery.com account please login with your email and password.</p>
    <div class="contact-form-input">
        <input
          type="email"
          class="form-control rounded border-white form-input mb-2"
          style="background: transparent; color: white"
          name="email"
          placeholder="Email"
          required
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          [(ngModel)]="email"
        />
      </div>
    
      <div class="contact-form-input">
        <input
          type="password"
          class="form-control rounded border-white form-input"
          style="background: transparent; color: white"
          name="password"
          placeholder="password"
          required
          [(ngModel)]="password"
        />
      </div>

      <div class="d-flex justify-content-center align-items-center py-2 mt-2">
    <button style="    
    background: #FF004F;
    border: none;
    font-weight: bold;
    padding: 8px 29px;
    color: white!important;"
    (click)="loginDGalery()"
    translate
    >Link</button>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-2">
        <span class="text-center" style="cursor: pointer;" (click)="register = !register" translate>don't have an account?</span>
    </div>

</div>

<div class="p-5 rounded" *ngIf="register"
style="background-color: #161616; width: 80vh; filter: drop-shadow(0px 0px 10px #ff004f);">
    <h2 mat-dialog-title class="title" translate>Sign up</h2>
    <p translate>If you do not have a dgalery.com account, register with your wallet.</p>

    <div class="contact-form-input">
      <input
        type="email"
        class="form-control rounded border-white form-input mb-2"
        style="background: transparent; color: white"
        name="email"
        placeholder="Email"
        required
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
        [(ngModel)]="email"
      />
    </div>

    <div class="contact-form-input">
      <input
        type="text"
        class="form-control rounded border-white form-input mb-2"
        style="background: transparent; color: white"
        name="username"
        placeholder="username"
        required
        [(ngModel)]="name"
      />
    </div>
  
    <div class="contact-form-input">
      <input
        type="password"
        class="form-control rounded border-white form-input mb-2"
        style="background: transparent; color: white"
        name="password"
        placeholder="password"
        required
        [(ngModel)]="password"
      />
    </div>

    <div class="contact-form-input">
      <input
        type="password"
        class="form-control rounded border-white form-input"
        style="background: transparent; color: white"
        name="confirmPassword"
        placeholder="confirm password"
        required
        [(ngModel)]="confirmPassword"
      />
    </div>

    <div class="d-flex justify-content-center align-items-center py-2 mt-2">
      <re-captcha 
        (resolved)="resolved($event)"
        siteKey="6LcfNb4pAAAAABYaw-P9EvP3E_lTbSq5zw9rnXEK">
      </re-captcha>
    </div>

      <div class="d-flex justify-content-center align-items-center py-2">
            <button style="    
            background: #FF004F;
            border: none;
            font-weight: bold;
            padding: 8px 29px;
            color: white!important;
            margin-top: 1rem;" 
            (click)="registerWallet(wallet)"
            translate>Register with wallet</button>
      </div>

</div>

<app-loader *ngIf="loading" loadingText="{{loaderText}}"></app-loader>