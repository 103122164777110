<div class="mt-4" [routerLink]="'/collection/profile/' + item.id " style="cursor: pointer;">
    <div class="author_list_pp1"> 
        <a href="javascript:void" >
            <img class="lazy" [src]="base_url + '/static/uploads/profile-' + item.image + '.png'" 
            alt="foto de perfil" (error)="errorImage($event)">
            <div class="check-position">
                <i class="fa fa-check "></i>
            </div>
        </a>
    </div>
    <div class="author_list_info d-flex align-items-center" style="background: #1f1f1f;
    border-radius: 10px; height: 4rem;">
        <div class="d-flex flex-column">
            <a href="javascript:void"class="author_text">{{item.username}}</a>
            <span style="font-size: 15px;">{{item.profit}} USDT</span>
        </div>
    </div>
</div>
