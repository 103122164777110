import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface FaqItem {
  question: string;
  answer: string;
  showAnswer: boolean;
}
@Component({
  selector: 'app-faq-item',
  templateUrl: './faq-item.component.html',
  styleUrls: ['./faq-item.component.css']
})
export class FaqItemComponent {
  @Input()faq!: FaqItem;
  constructor(
    private translate: TranslateService
    ) { 
    
  }
}
