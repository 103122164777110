import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { Clipboard } from "@angular/cdk/clipboard"
import { UserService } from 'src/app/services/user.service';
import { Router, NavigationEnd } from '@angular/router'
import {filter} from 'rxjs/operators';
import { config } from 'src/app/constants/config';
import {MatSnackBar} from '@angular/material/snack-bar';
import { API } from 'src/app/constants/api';
import { HelperService } from 'src/app/services/helper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements AfterViewInit {
  subscription:any;
  image: any;
  banner: any;
  isFixedSidebar:boolean = false;
  isToggled:boolean = true;

  @ViewChild('topContainer', { read: ElementRef, static:false })topContainer: ElementRef;
  @ViewChild('profileContainer', { read: ElementRef, static:false })profileContainer: ElementRef;
  innerHeight:number = 0;
  topMenuHeight:number = 0;
  topMenuHeightAdjustment:number = 0
  topHeight:number = 0;
  mediaBase:string = config.media_path
  type:string = "collected"
  currentUserID: string = "";
  currentUser: any;
  ownProfile: boolean = false;
  userInfo:any = null;
  profile_image: string = 'nouser.jpg'
  profile_cover: string = 'default.jpg'
  profile_name:string = '';

  avatarname:string = ''

 
  covername:string = ''
  spinner: boolean = false;

  imageProfile: string = '';
  imageBanner: string = '';
  loaderText: string = '';

  constructor(
    private userService: UserService,
    private router: Router,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {
    this.topContainer = this.profileContainer = this.subscription
    // this.currentUser = this.userService.getUser()
    this.userService.user.subscribe((user) => {
      this.currentUser = user;
      //console.log(this.currentUser);
    });
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event:any) => {
      var url = event.url.split("/").pop();
      if(url != 'created' && url != 'activity' && url != 'offers' && url != 'favorited') {
        this.type = "collected";
      } else {
        this.type = url
        //console.log(this.type);
      }
      var user_id = event.url.replace('/'+this.type,'').split("/").pop();
      if(this.currentUserID != user_id) {
        this.currentUserID = user_id;
        if(this.currentUser) {
          this.ownProfile = (this.currentUser.user_id == this.currentUserID)? true: false;
        } else {
          this.ownProfile = false;
        }

        if(!this.ownProfile && (this.type != "created" && this.type != "collected" && this.type != "favorited" )) {
          // location.href = config.base_url;
          //this.router.navigate(['/']);
        }
        this.getProfileInfo()
      }
    });


    this.image = this.userService.imageProfile$.subscribe((image) => {
      this.imageProfile = image;
    });
    this.banner = this.userService.banner$.subscribe((banner) => {
      this.imageBanner = banner;
    });


    /*
    this.imageProfile = `${API.dgalery_url}/static/uploads/profile-${this.currentUser.idDgalery}.png?t=${Date.now()}`
    this.imageBanner = `${API.url}/images/profiles/bn-${this.currentUser.id}.png` 
    */
  }

  ngOnInit(): void {
    this.topMenuHeight = window.innerWidth>990 ? 73 : 58;
    this.innerHeight = window.innerHeight - this.topMenuHeight;
    this.topMenuHeightAdjustment = window.innerWidth>990 ? 78 : 78;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.image.unsubscribe();
    this.banner.unsubscribe();
  }

  ngAfterViewInit() {
    this.topHeight = this.topContainer.nativeElement.clientHeight;
  }

  /**
   * This is the function which used to get profile information for user
   */
  getProfileInfo = () => {
    this.userService.viewProfile(this.currentUserID).subscribe(result=>{
      var resulter:any = result;
      this.userInfo = resulter.result;
      this.updateProfileInfo()
    })
  }

  /**
   * This is the function which initiate profile image upload on button click
   */
  uploadAvatar = () => {
    this.spinner = true;

  }

  /**
   * This is the function which initiate profile cover upload on button click
   */
  uploadCover = () => {
    this.spinner = true;

  }

  /**
   * This is the function which parse profile information for display
   */
  updateProfileInfo = () => {
    this.profile_cover = this.userInfo.profile_cover ? this.userInfo.profile_cover : 'default.jpg';
    this.profile_image = this.userInfo.profile_image.length > 0 ? this.userInfo.profile_image : 'nouser.jpg';
    this.profile_name = this.userInfo.first_name + ' ' + this.userInfo.last_name
  }

  /**
   * This is the function which used to copy ethereum address
   */
  copyAddress=()=> {
     this.clipboard.copy(this.userInfo.public_key)
     this.snackBar.open('wallet address copied', "", { duration: 2000 });
  }

  /**
   * This is the function which handle sticky sidebar
   */
  scrollHandler(event:any) {
    // //console.log(event.target.scrollTop);
    // //console.log(this.topHeight);
    if(event.target.scrollTop<(this.topHeight+this.topMenuHeightAdjustment)) {
      this.isFixedSidebar = false;
    } else {
      this.isFixedSidebar = true;
    }
  }

  /**
   * This is the function which used to minimize and maximize the profile side bar
   */
  minimizeMenu = () => {
    this.isToggled = this.isToggled ? false : true;
  }

  /**
   * This is the function which used to navigate user to different profile pages
   */
  profileLink = (pageName: any) => {
    if (pageName == 'setting') {
      this.router.navigate(['/settings/']);
    } else {
      this.router.navigate(['/profile/' + pageName]);
    }

    try {
      this.profileContainer.nativeElement.scrollTop = 0
    } catch(err) { }
  }


  /**
   * This is the function which used to update user profile information through api
   */
  updateProfileApi = (params:any) => {
    this.userService.updateApi(params,this.currentUser.user_id).subscribe(result=>{
     this.spinner = false;
     this.snackBar.open(result.message, "", { duration: 2000 });
     if(result.status == true) {
       localStorage.setItem('token',result.return_id);
      //  location.href = config.base_url + '/profile/' +this.currentUser.user_id;
       this.router.navigate(['/profile/' +this.currentUser.user_id]);
     }
    })
  }

  /**
   * This is the function which used to handle window resize event
   */
  onFileSelected(event:any, type:string) {
    const file: File = event.target.files[0];

    if (file) {
      
      this.loaderText = this.translateService.instant('Uploading image...');
      this.spinner = true;
      this.userService.uploadImage(file, type).subscribe(response => {
        // Maneja la respuesta aquí
        if(type == 'img-profile') {
          this.imageProfile = `${API.url}/images/${response.path}?t=${Date.now()}`;
          this.snackBar.open(this.translateService.instant('Profile image uploaded'), "", { duration: 2000 });
        }
        if(type == 'img-banner') {
          this.imageBanner = `${API.url}/images/${response.path}?t=${Date.now()}`;
          this.userService.updateBanner(this.imageBanner);
          this.snackBar.open(this.translateService.instant('Profile banner uploaded'), "", { duration: 2000 });
        }
        //this.userService.refreshUser();
        this.spinner = false;
        this.loaderText = '';
        //console.log(response);
      }, error => {
        // Maneja el error aquí
        this.spinner = false;
        this.loaderText = '';
        this.snackBar.open(this.translateService.instant('Error uploading image'), "", { duration: 2000 });
        //console.log(error);
      });
    }
  }

  uploadProfileImage(event:any) {
    const file: File = event.target.files[0];
    const idDgalery = this.currentUser.idDgalery;

    if (file) {
      this.loaderText = this.translateService.instant('Uploading image...');
      this.spinner = true;
      this.userService.uploadProfileImage(file, idDgalery).subscribe(response => {
        // Maneja la respuesta aquí
        //console.log(response);
        this.imageProfile = `${API.dgalery_url}/static/uploads/profile-${idDgalery}.png?t=${Date.now()}`;
        this.userService.updateImageProfile(this.imageProfile);
        this.snackBar.open(this.translateService.instant('Profile image uploaded'), "", { duration: 2000 });
        this.spinner = false;
        this.loaderText = '';
      }, error => {
        // Maneja el error aquí
        this.spinner = false;
        this.loaderText = '';
        this.snackBar.open(this.translateService.instant('Error uploading image'), "", { duration: 2000 });
        //console.log(error);
      });
    }

  }
 
  handleImageError(event: any) {
    if(this.currentUser.role == 1001) event.target.src = "https://app.dgalery.com/assets/logo2.png";
    else event.target.src = `${API.url}/images/profiles/pr-0.png`;
  }

}
